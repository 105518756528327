import React, { useState, useEffect, useCallback } from "react";
import { Button, Table, Input, Popup, Accordion, Icon } from "semantic-ui-react";
import { postAPI } from "../../../apiServices";
import { CBadge } from "@coreui/react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleLeft, faAngleRight, faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getUrlFileExtension } from "../../../constants/comonFunction";
import ImageGalleryWithCaption from "../../../common-components/image-viewer/ImageGalleryWithCaption"
import EditOrder from "../EditOrder";
const defaultState = {
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 50,
  loading: false,
  buyerOrderSubtotal: 0,
  sellerOrderSubTotal: 0,
  orderRedeemedLP: 0,
  orderCreditedLP: 0,
  buyerGrandTotal: 0,
  sellerGrandTotal: 0,
  orderCouponDiscount:0,
  orderCouponName:"",
  shipping: 0,
  advance:{},
  remainingAmount: {},
  totalNoOfItems: 0,
  totalMarginInPercent : 0,
  sellerTotalTax : 0,
  buyerTotalTax : 0,
  sellerRoundOffGrandTotal : 0
};

const OrderItem = ({ details = {}, isLoading = false, section = "", canEdit="" }) => {

  const [state, setState] = useState(defaultState);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isEditOrder, setIsEditOrder] = useState(details?.isEditOrderAllowed);
  const [orderData, setOrderData] = useState([]);
  const [customInvoiceAmount, setCustomInvoiceAmount] = useState(details?.customInvoiceData?.invoiceAmount ? details?.customInvoiceData?.invoiceAmount : 0)
  const [showAdvanceDetail, setShowAdvanceDetail] = useState(false);
  const [showBalanceDetail, setShowBalanceDetail] = useState(false);

  const [addItemModal, setAddItemModal] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const localNavigation = JSON.parse(localStorage.getItem("module"));
      let isEditOrderAllowed = false;
      localNavigation?.forEach(Orderelement => {
      // if(Orderelement.moduleName === 'Orders'){
      //   isEditOrderAllowed = Orderelement.isEditOrderAllowed
      //   setIsEditOrder(isEditOrderAllowed)
      // }
    });
      // setIsEditOrder(canEdit)
      fetchOrders();
    }
    fetchData();
  }, []);

  let sellerOrderSubTotal = [];
  let buyerOrderSubTotal = [];
  const Total = (item) => {
    orderData.map((order) => {
      if (order?.orderItemId === item?.orderItemID) {
        return (
          <>
            {sellerOrderSubTotal?.push(
              parseInt(order?.quantity || item?.setQty) *
                parseInt(
                  order?.sellerPricePerItem || item?.sellerPricePerItem
                ) *
                item.setItem
            )}
            {buyerOrderSubTotal?.push(
              parseInt(order?.quantity || item?.setQty) *
                parseInt(order?.buyerPricePerItem || item?.buyerPricePerItem) *
                item.setItem
            )}
          </>
        );
      }
    });
  };

  const onInit = () => {
    console.log('lightGallery has been initialized');
};


  const onValueChange = (value, key, orderItemID) => {
    setOrderData((previousOrderData) =>
      previousOrderData.map((order) => {
        if (order?.orderItemId === orderItemID) {
          return {
            ...order,
            [key]: value,
          };
        }
        return order;
      })
    );
  };

  const fetchOrders = useCallback(async (page = 0, recPerPage = 50) => {
    try {
      if (!details.orderID) {
        return;
      }
      setState((prevState) => ({ ...prevState, loading: true }));
      const data = {
        page,
        recPerPage,
        orderID: details.orderID,
      };
      const { status, payload } = await postAPI("/orders/items/list", data);
      console.clear();

      setState((prevState) => ({ ...prevState, loading: false }));
      // Swal.close();

      if (!status) {
        // if (message !== "No Order found")
        // toast.error(message || MESSAGES?.sellers?.defaultApiError);
        setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          totalEntries: defaultState.totalEntries,
          buyerOrderSubtotal: defaultState.buyerOrderSubtotal,
          sellerOrderSubTotal: defaultState.sellerOrderSubTotal,
          buyerGrandTotal: defaultState.buyerGrandTotal,
          sellerGrandTotal: defaultState.sellerGrandTotal,
          shipping: defaultState.shipping,
          orderRedeemedLP: defaultState.orderRedeemedLP,
          orderCreditedLP: defaultState.orderCreditedLP,
          orderCouponDiscount:defaultState.orderCouponDiscount,
          orderCouponName:defaultState.orderCouponName,
          remainingAmount:defaultState.remainingAmount,
          advance: defaultState.advance
        }));
        setOrderData(
          defaultState.entries.map((d) => ({
            orderItemId: d.orderItemID,
          }))
        );
      } else {
        setState((prevState) => ({
          ...prevState,
          entries: payload?.data || defaultState.entries,
          buyerOrderSubtotal:
            payload?.buyerOrderSubtotal || defaultState.buyerOrderSubtotal,
          sellerOrderSubTotal:
            payload?.sellerOrderSubTotal || defaultState.sellerOrderSubTotal,
          sellerGrandTotal:
            payload?.sellerGrandTotal || defaultState.sellerGrandTotal,
          buyerGrandTotal:
            payload?.buyerGrandTotal || defaultState.buyerGrandTotal,
          shipping: payload?.shipping || defaultState.shipping,
          orderRedeemedLP: payload?.orderRedeemedLP || defaultState.orderRedeemedLP,
          orderCreditedLP: payload?.orderCreditedLP || defaultState.orderCreditedLP,
          orderCouponDiscount:payload?.orderCouponDiscount || defaultState.orderCouponDiscount,
          orderCouponName:payload.orderCouponName  || defaultState.orderCouponName,
          remainingAmount:payload?.remainingAmount || defaultState.remainingAmount,
          advance: payload?.advance || defaultState.advance,
          totalNoOfItems: payload?.totalNoOfItems || defaultState.totalNoOfItems,
          totalMarginInPercent : payload?.totalMarginInPercent || defaultState.totalMarginInPercent,
          sellerTotalTax : payload?.sellerTotalTax || defaultState.sellerTotalTax,
          buyerTotalTax : payload?.buyerTotalTax || defaultState.buyerTotalTax,
          sellerRoundOffGrandTotal : payload?.sellerRoundOffGrandTotal || defaultState.sellerRoundOffGrandTotal,
          buyerRoundOffGrandTotal : payload?.buyerRoundOffGrandTotal || defaultState.buyerRoundOffGrandTotal
          // totalEntries: payload?.totRecords || defaultState.totalEntries,
        }));

        setOrderData(
          payload?.data.map((d) => ({ orderItemId: d.orderItemID })) ||
            defaultState.entries.map((d) => ({
              orderItemId: d.orderItemID,
            }))
        );
      }
    } catch (er) {
      console.log(er);
    }
  }, []);

  const SaveOrderEdit = async () => {
    const data = JSON.stringify({
      orderID: details?.orderID,
      itemsData: orderData,
    });

    const { payload } = await postAPI("/orders/update-details", data);
  };

  const UpdateEditOrderBtn = () => {
    Swal.fire({
      icon: "info",
      title: "Do you want to save changes ?",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonColor: "lightgray",
      showCancelButton: true,
    }).then(async (er) => {
      if (er.isConfirmed === true) {
        SaveOrderEdit();
        isLoading = true;
        setTimeout(function () {
          fetchOrders();
          Swal.fire("Saved!", "", "success");
          setIsEditModal(false);
        }, 1000);
      } else if (er.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleEditButtom = () => {
    setIsEditModal(true);
  };
  const handleCancelEdit = () => {
    setIsEditModal(false)
  };

  const handleSaveEdit = () => {
    setIsEditModal(false)
    fetchOrders()
  }

  const EditButton = () => {
    if (isEditOrder === true && isEditModal === false) {
      return (
        <>
          <button
            style={{ float: "right" }}
            className="btn btn-info"
            type="edit"
            onClick={() => {
              handleEditButtom();
            }}
          >
            Edit Order
          </button>
        </>
      );
    } else if (isEditOrder === true) {
      return (
        <>
          <EditOrder orderDetail={details} state={state} orderData={orderData} section={section} handleCancelEdit={handleCancelEdit} handleSaveEdit={handleSaveEdit} />
        </>
      );
    }
  };

  return (
    <>
      <div>
        <div>
          <Table className="celled">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="top-row-no-padding" colspan={2}></Table.HeaderCell>
                <Table.HeaderCell className="top-row-no-padding" colspan={2} style={{textAlign:"center"}}>PER ITEM</Table.HeaderCell>
                <Table.HeaderCell className="top-row-no-padding" colspan={2}></Table.HeaderCell>
                <Table.HeaderCell className="top-row-no-padding" colspan={2} style={{textAlign:"center"}}>SUB TOTAL</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell style={{minWidth:"100px"}}> Seller Price </Table.HeaderCell>
                <Table.HeaderCell style={{minWidth:"100px"}}> Buyer Price </Table.HeaderCell>
                <Table.HeaderCell style={{minWidth:"100px"}}>Item Count</Table.HeaderCell>
                <Table.HeaderCell style={{minWidth:"100px"}}>Quantity</Table.HeaderCell>
                <Table.HeaderCell style={{minWidth:"120px", textAlign:"right"}}>Seller</Table.HeaderCell>
                <Table.HeaderCell style={{minWidth:"120px", textAlign:"right"}}>Buyer</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {(state?.entries || []).map((item, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell className="d-flex">
                      <div>
                      {/* <ImageGalleryWithCaption images={item?.images} captions={[]} viewPreview={false} viewInitialImage={true} /> */}
                      <img src={item?.images?.[0]}  style={{width:"150px", height:"150px", borderRadius:"5px", padding:"10px"}} />
                      </div>
                      <div className="ms-3">
                      {item.productName}
                        <span className="product-name-tooltip">
                          {" "}<FontAwesomeIcon icon={faInfoCircle} />
                          <div className="tooltip-content">
                            <table key={index} className="table table-bordered">
                              <tr>
                                <th style={{padding:"4px 0"}}>Color</th>
                                <th style={{padding:"4px 0 4px 4px"}}>Size</th>
                                <th style={{padding:"4px0 4px 4px"}}>Qty</th>
                              </tr>
                              {item?.productIdentifier?.map((row, index) => {
                                return (<tr key={index}>
                                  <td>{row.color}</td>
                                  <td>{row.size}</td>
                                  <td>{row.itemQty}</td>
                                </tr>)
                              })}

                            </table>
                          </div>
                        </span>
                        <br />
                        <div className="pt-2 pb-2">
                          <CBadge className="p-2" color="warning">
                            {item.productType === 1
                              ? "Color Variant"
                              : "Set Variant"}
                          </CBadge>
                        </div>
                      </div>
                    </Table.Cell>

                    <Table.Cell>{item.skuCode}</Table.Cell>
                    <Table.Cell style={{}}>
                      {/* {item.productType == 1 ? item.priceOnApp : item.priceOnApp / item.setQty} */}
                      {/*Seller Price Per Item */}
                      {item.sellerPreGstItmPrice}
                      <div style={{fontSize:"11px"}}>{`${item?.sellerPreGstItmPrice} + ${item?.sellerItmTaxAmount} (${item.sellerTaxPercent * 100}%) = ${(parseFloat(item?.sellerPreGstItmPrice) + parseFloat(item?.sellerItmTaxAmount)).toFixed(2)}`}</div>
                    </Table.Cell>
                    <Table.Cell>
                      {/* Buyer Price Per Item */}
                      {item.buyerPreGstItmPrice}
                      <div style={{fontSize:"11px"}}>{`${item?.buyerPreGstItmPrice} + ${item?.buyerItmTaxAmount} (${item.buyerTaxPercent * 100}%) = ${(parseFloat(item?.buyerPreGstItmPrice) + parseFloat(item?.buyerItmTaxAmount)).toFixed(2)}`}</div>
                    </Table.Cell>

                    <Table.Cell>
                      <>{item.setItem}</>
                    </Table.Cell>
                    <Table.Cell>
                      <>
                      {item.setQty}
                      </>
                    </Table.Cell>

                    <Table.Cell style={{textAlign:"right"}}>
                    ₹{" "}{item.sellerSubTotal}
                    </Table.Cell>
                    <Table.Cell style={{textAlign:"right"}}>
                    ₹{" "}{item.buyerSubTotal}
                    </Table.Cell>
                  </Table.Row>
                );
              })}

              {/*
                order subtotal, and tax detail
              */}
              <Table.Row>
              <Table.Cell colspan={4}></Table.Cell>
                <Table.Cell colspan={2}>
                  <div className="mb-2">Order Subtotal</div>
                  <div className="mb-2">Tax amount</div>
                </Table.Cell>
                <Table.Cell>
                <span style={{float:"right", display:"inline-block"}}>
                  <div className="mb-2" style={{textAlign:"right"}}>
                  ₹{" "}
                    {state.sellerOrderSubTotal}
                  </div>

                  <div className="mb-2" style={{textAlign:"right"}}>
                  ₹{" "}{state.sellerTotalTax}
                  </div>

                  </span>
                </Table.Cell>
                <Table.Cell>
                  <span style={{float:"right", display:"inline-block"}}>
                    <div className="mb-2" style={{textAlign:"right"}}>
                    ₹{" "}
                      {state.buyerOrderSubtotal}
                    </div>
                    <div className="mb-2" style={{textAlign:"right"}}>₹{" "}{state?.buyerTotalTax}</div>
                  </span>
                </Table.Cell>
              </Table.Row>

              {/* handling change and discount row
                discount row show only when orderCouponDiscount exist and its value is greater than 0 */}
              <Table.Row>
              <Table.Cell colspan={4}></Table.Cell>
                <Table.Cell colspan={2}>
                  <div className="mb-2">Handling Charges</div>
                  {state?.orderCouponDiscount > 0 ? (
                    <div className="mb-2">
                      <div>Coupon Discount</div>
                      <div style={{fontWeight:"bold"}}>({state?.orderCouponName})</div>
                    </div>
                  ):("")}

                </Table.Cell>
                <Table.Cell>
                <span style={{float:"right", display:"inline-block"}}>
                  <div className="mb-2">
                  ₹{" "}0
                  </div>

                    {state?.orderCouponDiscount > 0 ? (
                      <div className="mb-2">
                      -
                      </div>
                    ) : ("")}
                  </span>
                </Table.Cell>
                <Table.Cell>
                  <span style={{float:"right", display:"inline-block"}}>

                  <div className="mb-2" style={{textAlign:"right"}}>₹{" "}{state?.shipping}</div>

                  {state?.orderCouponDiscount > 0 ? (
                    <div className="mb-2" style={{textAlign:"right"}}>(-) ₹{" "} {state?.orderCouponDiscount}</div>
                  ) : ("")}
                  </span>
                </Table.Cell>
              </Table.Row>

              {/* grand total row consist of seller and buyer subtotal */}
              <Table.Row>
                <Table.Cell colspan={4}></Table.Cell>
                <Table.Cell colspan={2}>
                <div style={{fontWeight:"bold", fontSize:"14px"}}>Gross Total ({`${state?.totalNoOfItems} Items`})</div>
                <div style={{fontSize:"14px"}}>Round Off </div>
                </Table.Cell>
                <Table.Cell>
                <div className="2" style={{fontWeight:"bold", fontSize:"14px", textAlign:"right"}}>
                    ₹{" "} {state?.sellerGrandTotal}
                  </div>
                  <div className="2" style={{fontSize:"14px", textAlign:"right"}}>
                    ₹{" "} {-(state?.sellerGrandTotal - state?.sellerRoundOffGrandTotal).toFixed(2)}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div style={{fontWeight:"bold", fontSize:"14px", textAlign:"right"}}>
                    ₹{" "} {state?.buyerGrandTotal}
                  </div>
                  <div style={{fontSize:"14px", textAlign:"right"}}>
                    ₹{" "} {-(state?.buyerGrandTotal - state?.buyerRoundOffGrandTotal).toFixed(2)}
                  </div>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell colspan={4}></Table.Cell>
                <Table.Cell colspan={2}>
                  <div style={{fontSize:"14px"}}>Total Margin </div>

                </Table.Cell>
                <Table.Cell>
                  <div className="2" style={{fontSize:"14px", textAlign:"right"}}>
                  {`${state?.totalMarginInPercent}% `}
                  </div>
                </Table.Cell>
                <Table.Cell>

                </Table.Cell>
              </Table.Row>


                {/* Payment detail row
                  it shows advance or all payment received and the payment to be collected */}
                  {(state?.advance?.actual + state?.advance?.sCash) > 0 ? (
                    <Table.Row>

                      <Table.Cell colspan={4}></Table.Cell>
                      <Table.Cell colspan={2}>
                        <span style={{fontWeight:"bold", fontSize:"14px"}}>Advance </span>
                        {showAdvanceDetail && state?.advance?.sCash > 0 && (<>
                          <div>{" "}<br /></div>
                          <div style={{color:"#827c7c"}}>Actual Amount</div>
                          <div style={{color:"#827c7c"}}>SCash Used</div>
                        </>)}

                      </Table.Cell>
                      <Table.Cell>-
                      </Table.Cell>
                      <Table.Cell>

                        <div style={{fontSize:"14px", textAlign:"right"}}>
                        <div style={{fontWeight:"bold"}}>
                          ₹{" "}{state?.advance?.actual > 0 ? state?.advance?.actual - state?.advance?.sCash : state?.advance?.actual}
                          {(state?.advance?.actual > 0 && state?.advance?.sCash > 0) &&
                          <FontAwesomeIcon icon={showAdvanceDetail ? faAngleDown : faAngleRight} style={{ cursor: "pointer", float:"right", marginTop:"4px" }} onClick={()=>setShowAdvanceDetail(!showAdvanceDetail)} />
                          }
                        </div>{" "} {" "}
                        {state?.advance?.sCash > 0 ? (<>
                          <div style={{fontSize:"12px", textDecoration:"line-through"}}>₹{" "}{state?.advance?.actual}</div>
                          {showAdvanceDetail ? (<>
                            {/* <FontAwesomeIcon icon={faAngleDown} style={{ cursor: "pointer", float:"right" }} onClick={()=>setShowAdvanceDetail(!showAdvanceDetail)} /> */}
                            <div style={{color:"#827c7c"}}>{" "}₹{" "}{state?.advance?.actual}</div>
                            <div style={{color:"#827c7c"}}>(-)₹{" "}{state?.advance?.sCash}</div>
                          </>) : (<>

                          </>)}
                        </>) : (<></>)}


                        </div>
                      </Table.Cell>
                      </Table.Row>
                  ):(<></>)}

              <Table.Row>
              <Table.Cell colspan={4}></Table.Cell>
              <Table.Cell colspan={2}>
                <div style={{fontWeight:"bold", fontSize:"14px"}}>Amount to be collected</div>
                <div style={{color:"#827c7c"}}>Actual Amount</div>
                <div style={{color:"#827c7c"}}>S-Cash Redeemed</div>
              </Table.Cell>
              <Table.Cell>-
              </Table.Cell>
              <Table.Cell>
                <div style={{fontSize:"14px", textAlign:"right"}}>
                <div style={{fontWeight:"bold"}}>
                  ₹{" "} {state?.remainingAmount?.final}
                  </div>
                  <div>₹{" "}{state?.remainingAmount?.actual}</div>
                  <div>₹{" "}{state?.remainingAmount?.sCash}</div>
                </div>
              </Table.Cell>
              </Table.Row>

              {/* It shows info about seller custom invoice or platform seller invoice
                it also shows difference amount of seller grand total and the custom invoice amount
                if there is any difference in seller grand total and custom invoice amount use red color to show noticiable difference */}
              <Table.Row>
                <Table.Cell colspan={8}>
                  {details?.isCustomInvoice === 1 && <>
                    <div className="mb-2">
                      <strong>Showroom's Seller Invoice: </strong> ₹{" "}{state.sellerGrandTotal} || &nbsp;
                      <strong>Seller's Custom Invoice: </strong> ₹{" "}{customInvoiceAmount} ||  &nbsp;
                      <strong>Diff: </strong>{state.sellerGrandTotal - customInvoiceAmount === 0 ? (<>{state.sellerGrandTotal - customInvoiceAmount}</>) : (<><span style={{color:"#ff0000"}}>{state.sellerGrandTotal - customInvoiceAmount}</span></>)}
                    </div>

                    </>}
                </Table.Cell>


              </Table.Row>
            </Table.Body>
          </Table>

          {/* add item modal */}



          {/* Order can be edit only when order is in placed, confirmed or ready state,
            after this edit button should not visible */}
          {/* {section === "placed" ||
          section === "confirmed" ||
          section === "packed" ? (
            <>{EditButton()}</>
          ) : (
            ""
          )} */}

<>{EditButton()}</>
        </div>
      </div>
    </>
  );
};

export default React.memo(OrderItem);
