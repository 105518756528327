import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  OrderItem,
  Payment,
  BuyerDetails,
  BuyerHistory,
  SellerDetails,
  RemarksHistory,
  Settings,
  ReturnDetails,
  RefundDetails
} from "./tab-panels";
import {
  CCard,
  CCardBody,
  CCardText,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import Modal from 'react-bootstrap/Modal';
import { Button } from "semantic-ui-react";
import { Input } from 'reactstrap';
import Swal from "sweetalert2";
import Moment from 'moment';
import {getAPILMS, postAPI, uploadBiltiImageAPI} from '../../apiServices'
import OrderPod from "./Pod";
import PodDetail from "./tab-panels/PodDetail";
import SellerInvoice from "./sellerInvoice";
import { faDownload, faEdit, faExclamationTriangle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import CancelOrder from "./CancelOrder";
import EInvoiceTop from "../../assets/icons/e-inv-5.png";
import { compareCurrentTimeWithGiven, isBeforeGivenHours } from "../../constants/comonFunction";
import OrderShip from "./OrderShip";
import moment from 'moment';
import { parse } from 'date-fns';

const noop = () => {};
const ORDER_STATUS = [
  { label: "Active", value: "active", key: 1, type: "status", sections: ["new", "approved", "blocked"]},
  { label: "Inactive", value: "inactive", key: 0, type: "status", sections: ["new", "approved", "blocked"],},
  { label: "Blocked", value: "blocked", key: -1, type: "status", sections: ["approved"],},
  { label: "Approved", value: "approved", key: 1, type: "approve", sections: ["new", "rejected"],},
  { label: "Not Approved", value: "not-approved", key: 0, type: "approve", sections: ["rejected"],},
  { label: "Rejected", value: "rejected", key: 2, type: "approve", sections: ["new"],},
];
const pocRestricted = ["bnpl", "online", "srb2b-credit"]

const TABS = [
  { label: "Order Item", key: "orderItem", panelComponent: OrderItem, statusKey: "companyProfileCompleted", canEdit:false},
  { label: "Payment & PoC", key: "bank", panelComponent: Payment, statusKey: "bankProcessCompleted", canEdit:false},
  { label: "Buyer Details", key: "brand", panelComponent: BuyerDetails, statusKey: "brandProcessCompleted", canEdit:false},
  { label: "Buyer History", key: "history", panelComponent: BuyerHistory, statusKey: "historyCompleted", canEdit:false},
  { label: "Seller Details", key: "employee", panelComponent: SellerDetails, statusKey: "historyCompleted", canEdit:false},
  { label: "PoD", key: "podDetail", panelComponent: PodDetail, statusKey: "podDtail", canEdit:false},
  { label: "Settings", key: "settings", panelComponent: Settings, statusKey: "settings", canEdit:false},
  { label: "Returns", key: "returns", panelComponent: ReturnDetails, statusKey: "returns", canEdit:false},
  { label: "Refund Details", key: "refundDetail", panelComponent: RefundDetails, statusKey: "remarks", canEdit:false},
  { label: "Remarks", key: "remarks", panelComponent: RemarksHistory, statusKey: "remarks", canEdit:false},
];

const defaultState = {
  activeTabIndex: -1,
  sellerStatus: ORDER_STATUS.find((status) => status.type === "status"),
  approveStatus: ORDER_STATUS.find((status) => status.type === "approve"),
  company: null,
  bank: null,
  brand: null,
  category: null,
  action: null,
  isFetching: {
    company: false,
    bank: false,
    brand: false,
    category: false,
    commercials: false,
  },
};
const localPermits = JSON.parse(localStorage.getItem("module"));
const roleId = JSON.parse(localStorage.getItem("roleId"));


const isDateInRange = (givenDate, startDate, endDate) => {
  const dateToCheck = new Date(givenDate);
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  return dateToCheck >= startDateObj && dateToCheck <= endDateObj;
};

const OrderPage = ({
  entry = {},
  section = "",
  onStatusChange = onStatusChange(),
  downlaodInvoice = noop,
  updateBilti = updateBilti(),
  widnowLocation = widnowLocation
}) => {
  const [state, setState] = useState(defaultState);
  const [confirmBtnLabel, setConfirmBtnLabel] = useState("");
  const [defaultAction, setDefaultAction] = useState("");
  const [biltiImageBin, setBiltiImageBin] = useState();
  const [biltiImage, setBiltiImage] = useState();
  const [biltiDate, setBiltiDate] = useState(entry?.lorryReceiptDetails?.generationDate ? parse(moment.utc(entry.lorryReceiptDetails.generationDate).format('DD-MM-YYYY'), 'dd-MM-yyyy', new Date()) : '')
  const [show, setShow] = useState(false);
  const [showCancel, setShowCancel] = useState(false)
  const [rfloatBtn, setrFloatBtn] = useState("none")
  const [viewBilti, setViewBilti] = useState(false)
  const [canEdit, setCanEdit] = useState(false);
  const [showPod, setShowPod] = useState(false);
  const [offset, setOffset] = useState(0);
  const [totalNetBalance, setNetbalance] = useState(entry?.totalNetBalance ? entry?.totalNetBalance : entry?.netAmount)
  const [totalDue, setTotalDue] = useState(entry?.totalDue ? entry?.totalDue : entry?.netAmount)
  const [totalCollected, setTotalCollected] = useState(entry?.totalCollected ? entry?.totalCollected : 0)
  const [sellerInvoiceModal, setSellerInvoiceModal] = useState(false);
  const [sellerInvoiceData, setSellerInvoiceData] = useState()
  const [remarksCount, setRemarksCount] = useState(entry?.remarksCount)
  const [canExtendReturnValidity, setCanExtendReturnValidity] = useState(false);
  const [showOrderShipModal, setShowOrderShipModal] = useState(false)
  const [isShipped, setIsShipped] = useState(entry?.shipmentDetails?.status === 0 ? false : true)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleViewBiltiClose = () => setViewBilti(false);
  const handleViewBiltiShow = () => setViewBilti(true);

  defaultState.action = section;

  const getScrollPosition = () => {
  }

  useEffect(() => {
    const localNavigation = JSON.parse(localStorage.getItem("module"));
    let modSection = section;
    if(section === 'packed'){
      modSection = "packaged"
    }
    localNavigation?.forEach(Orderelement => {
      if(Orderelement.moduleName === 'Orders'){
        let canIsEdit = false;
        Orderelement?.childItems?.forEach(element => {
          if('/order/'+modSection === element.modulePath){
            canIsEdit = element.isEdit === 0 ? false:true
            setCanEdit(canIsEdit)
            // setCanEdit(entry?.isEditOrderAllowed)
          }
        });
      }
    });
    let _defaultAction = "";
    let cnfBtnLab = "";
    if (section === "placed") {
      cnfBtnLab = "Confirm";
      _defaultAction = "confirm";
    } else if (section === "confirmed") {
      cnfBtnLab = "Mark Ready";
      _defaultAction = "ready";
    } else if (section === "packed") {
      cnfBtnLab = "Dispatch";
      _defaultAction = "dispatch";
    } else if (section === "dispatched") {
      cnfBtnLab = "Mark as deliver";
        _defaultAction = "deliver";

    }
    else if (section === 1) {
      cnfBtnLab = "";
      _defaultAction = "";
    } else {
      cnfBtnLab = "Cancel";
      _defaultAction = "cancel";
    }

    setConfirmBtnLabel(cnfBtnLab);
    setDefaultAction(_defaultAction);
  }, [section]);

  useEffect(() => {
    setState((prevState) => ({
      ...defaultState,
      sellerStatus: ORDER_STATUS.find(
        (status) => status.key === entry.status && status.type === "status"
      ),
      approveStatus: ORDER_STATUS.find(
        (status) =>
          status.key === entry.approvalStatus && status.type === "approve"
      ),
    }));
  }, [entry]);

  const handleTabChange = (index, userId, key) => {
    setState((prevState) => ({
      ...prevState,
      activeTabIndex: prevState.activeTabIndex === index ? -1 : index,
    }));
  };

  const handleShowCancel = (d) => {
    setShowCancel(d)
    if(d === true){
      if(roleId === 1 || roleId === 2){
        setrFloatBtn("right")
      }
      else{
        setrFloatBtn("none")
      }
    }else{
      setrFloatBtn("none")
    }

  }

  const handleShowPod = (d) => {
    setShowPod(d)
  }

  const handleShowOrderShip = (d) => {
    setShowOrderShipModal(d)
  }


  const updateNetDue = (receivedAmount) => {
    let dueAmount = entry?.totalNetBalance - receivedAmount
    setNetbalance(dueAmount)
    let tCollected = totalCollected +receivedAmount;
    setTotalCollected(tCollected)
  }


  const handleStatusChange = (status = {}, type = "", id = null, action = "", entryName = "") => {
    onStatusChange(status, id, action, entryName, () => {
      setState((prevState) => ({
        ...prevState,
        [type]: status,
      }));
    });
  };

  const handleShowSellerInvoiceModal = (d) => {
    setSellerInvoiceModal(d)
  }

  const handleSellerInvoicePayload = (status, payload) => {
    setSellerInvoiceModal(!sellerInvoiceModal)
    setSellerInvoiceData(payload)
    onStatusChange({orderId:entry.orderID, confirmBtnLabel:status, payloadData:payload});
  }

  const handleShowOrderShipModal = (status, resData) => {
    if(status === 1){
      setIsShipped(resData?.payload?.meta?.success)
    }
    setShowOrderShipModal(false)
  }

  const handleOrderShipPayload = (status, payload) => {
    setShowOrderShipModal(!showOrderShipModal)
  }

  const handleCancelOrderShip = async(orderID) => {
    let res = await getAPILMS('/orders/cancel/'+orderID)
    if(res?.status === 1){
      setIsShipped(false)
      toast.success(res.message)
    }else{
      toast.error(res.message)
    }
  }

  const handleGetShipDetail = async(orderID) => {
    let res = await getAPILMS('/orders/'+orderID)
    if(res?.status === 1){
      if(res.payload?.waybillNumber){
        setIsShipped(true)
        toast.success(res.message)
      }
    }
  }

  const handlePocPodPayload = (status, payload) => {
    onStatusChange({orderId:entry.orderID, confirmBtnLabel:status, payloadData:payload});
  }

  const handleCancelPayload = (status, payload) => {
    onStatusChange({orderId:entry.orderID, confirmBtnLabel:status, payloadData:payload});
  }

  const updateOrderStatusBtn = (confirmBtnLabelText, isBuyerGSTAvailable = null) => {
    let defaultActions = confirmBtnLabelText == "cancel" ? "cancel" : defaultAction;
        defaultActions = confirmBtnLabelText == "rto" ? "rto" : defaultAction;
    let defaultMessage = defaultActions == "deliveredToHub" ? "deliver" : defaultActions
    let warningMessage = "<div style='font-size:22px'>Do you want to " + defaultMessage.toLowerCase() + " this order" + (defaultActions == "deliveredToHub" ? " to hub" : "") + '?</div>'
    let finalWarningMessage = '';
    if(defaultActions === 'dispatch' && isBuyerGSTAvailable !== null){

      finalWarningMessage = `<div class="alert alert-warning"><strong><i class="fa fa-exclamation-triangle" style="font-size:16px;color:darkorange"></i></strong> E-invoice is required for physical dispatch</div>`+warningMessage
    }else{
      if(defaultActions === 'rto'){
        finalWarningMessage = "<div style='font-size:22px'>Do you want to mark this order as RTO?</div>"
      }else{
        finalWarningMessage = warningMessage;
      }

    }


    Swal.fire({
      icon: "info",
      html: `${finalWarningMessage}`,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonColor: "lightgray",
      showCancelButton: true,
    }).then(async (er) => {
      if (er.isConfirmed === true) {
        onStatusChange({orderId:entry.orderID, confirmBtnLabel:defaultActions, payloadData:null});
      }
    });

  };

  // Permissions
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleName == "Orders")
  );

  const [Placed, setPlaced] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Placed")
  );
  const [Confirmed, setConfirmed] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Confirmed")
  );
  const [ready, setReady] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Ready")
  );
  const [dispatch, setDispatch] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Dispatched")
  );
  const [delivered, setDelivered] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Delivered")
  );

  const [lost, setLost] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Lost")
  );

  const [Failed, setFailed] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Failed")
  );

  const [Rto, setRto] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "RTO")
  );

  const [Rpath, setRpath] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Rpath")
  );
  const downloadCSVPermissions = () => {
    if (permissions?.isDownload === 0) {
      return true;
    }
    if (section === 'ready' && ready?.isDownload === 0) {
      return true;
    }
    if (section === 'delivered' && delivered?.isDownload === 0) {
      return true;
    }
    if (section === 'dispatched' && dispatch?.isDownload === 0) {
      return true;
    }
    if (section === 'lost' && lost?.isDownload === 0) {
      return true;
    }
    return false;
  };

  const confirmBtnPermissions = () => {
    if (permissions?.isEdit === 0) {
      return true;
    }
    if (dispatch?.isEdit === 0) {
      return true;
    }
    if (Placed?.isEdit === 0) {
      return true;
    }
    if (Confirmed?.isEdit === 0) {
      return true;
    }
    if (ready?.isEdit === 0) {
      return true;
    }
    return false;
  };

  const cancelBtnPermission = () => {

    if (permissions?.isCancelAllowed === 0) {
      return false;
    }
    if (dispatch?.isCancelAllowed === 0) {
      return false;
    }
    if (Placed?.isCancelAllowed === 0) {
      return false;
    }
    if (Confirmed?.isCancelAllowed === 0) {
      return false;
    }
    if (ready?.isCancelAllowed === 0) {
      return false;
    }
    return true;
  }

  const RpathButtonPermission = () => {
    if (permissions?.isEdit === 0) {
      return true;
    }
    if (Rpath?.isEdit === 0) {
      return true;
    }
  };

  const saveBilti = async (orderId, biltiFile) => {
    // let currentPosition = window.scrollY;
    let biltiNumber = document.getElementById('biltiNumber'+orderId).value;
    let biltiImageCurr = document.getElementById('biltiImage'+orderId).value;
    document.getElementById('biltiDateError'+orderId).textContent = '';
    if(!biltiDate){
      document.getElementById('biltiDateError'+orderId).textContent=" Required Generation Date";
      return
    }

    if(!biltiNumber && !biltiImageBin && !biltiFile){
      document.getElementById('biltiError'+orderId).textContent=" Lorry Receipt/Bilti Number or Lorry Receipt/Bilti image/pdf is required";
      return
    }

    let resImg;
          let res;
          let message;
          if(biltiImageCurr){
            let dataImage = {
              file:biltiImageBin[0],
              orderId: orderId,
              generationDate: format(new Date(biltiDate),'yyyy-MM-dd H:mm:ss')
            }
            resImg = await uploadBiltiImageAPI('/orders/lorry-receipt/upload', dataImage, orderId)
            if(resImg.status === 1){
              setBiltiImage(resImg.payload.lorryReceipt);
              message = resImg.message;
            }
            else{
              toast.error(resImg.message);
              return
            }
          }

          if(biltiNumber || biltiImage || biltiFile){
            let data = {
              orderId: orderId,
              receiptNumber: biltiNumber,
              generationDate: format(new Date(biltiDate),'yyyy-MM-dd H:mm:ss')
            }
            res = await postAPI('/orders/lorry-receipt/update', data)
            message = res.message;
          }

          if(res?.status === 1 || resImg?.status === 1){
            toast.success(message);
          }else{
            toast.error(message);
          }
          // window.scrollTo(0, parseInt(offset))
          updateBilti(widnowLocation);
          setViewBilti(false);
          setShow(false);

  }

  useEffect(() => {
    const specificPosition = 800; // Replace with the desired vertical position
  });

  const handleTotalDue = (receivedAmount) => {
    let dueAmount = totalNetBalance - receivedAmount
    setNetbalance(dueAmount)
    let tCollected = totalCollected +receivedAmount;
    setTotalCollected(tCollected)
  }

  const downloadInvoiceData = async (type, orderId) => {
    try {
      var url;
      if(type === 0){
        url = "/orders/invoice/buyer-order"
      }else if(type === 1){
        url = "/orders/invoice/showroom";
      }
      else if(type === 2){
        url = "/orders/seller-custom-invoice-url";
      }
      else if(type === 3){
        url = "/orders/invoice/buyer-proforma";
      }
      else if(type === 4){
        url = "/orders/invoice/seller-proforma";
      }
      const { status, payload, message } = await postAPI(url, {
        orderId: orderId,
      });
      Swal.close();
      setState((prevState) => ({ ...prevState, loading: false }));

      if (!status) {
        toast.error(message);
      } else {
        window.open(payload.url, '_blank');
        toast.success(message);
      }
    } catch (er) {

    }
  };

  const readMore = (reason, orderID) => {
    let more = document.getElementById("cancellationReason"+orderID)
    more.textContent = reason;
    document.getElementById("moreReason"+orderID).style.display = "none"
    document.getElementById("lessReason"+orderID).style.display = "inline"
  }

  const lessMore = (reason, orderID) => {
    let less = document.getElementById("cancellationReason"+orderID)
    less.textContent = reason;
    document.getElementById("lessReason"+orderID).style.display = "none"
    document.getElementById("moreReason"+orderID).style.display = "inline"
  }

  const showSwitchPayBalance = async (userId, orderId) => {
    let buyerDom = document.getElementById("showSwitchPayBalance"+orderId)
    if(buyerDom?.textContent === 'Check SwitchPe Balance'){
      buyerDom.style.color = "#3c4b64";
      buyerDom.style.cursor = 'text';
    }
    // let res = await postAPI('/rupifi/check-credit-eligibility', {userId: userId})
    let res = await postAPI('/switchpe/check-credit-line', {buyerID: userId})
    if(res?.status === 1){
      buyerDom.textContent = `${res?.payload?.availableLimit}`;
      // let rupifiStatus = res?.payload?.rupifiResponse;
      // if(rupifiStatus?.success === true){
      //   let rupifiData = rupifiStatus?.data;
      //   if(rupifiData?.status === 'ACTIVE'){
      //     let status = rupifiData?.account?.status;
      //     let balance = rupifiData?.account?.balance?.formattedValue;
      //     buyerDom.textContent = balance.replace('₹ ', '') + '('+status+')';
      //   }else{
      //     buyerDom.textContent = "Not registered on rupifi"
      //   }

      // }else{
      //   buyerDom.textContent = "Not registered on rupifi"
      // }
    }else{
      buyerDom.textContent = `${res?.message}`;
    }

  }

  useEffect(() => {
    const specificPosition = 800; // Replace with the desired vertical position
  });

  const updateRemarkCount = (newCount) => {
    setRemarksCount(newCount)
  };

  return (<>
    <CCard key="orders" onScroll={() => getScrollPosition()} style={{marginBottom:"20px"}}>
      <CCardBody>
        <div className="d-flex justify-content-between mb-6">
          <div  style={{width:"50%"}}>
            {[
              { label: "Order Number", key: "orderNumber" },
              { label: "Payment", key: "paymentMode" },
              { label: "Buyer", key: "buyerName" },
              { label: "Buyer Trade Name", key: "buyerShopName"},
              { label: "Seller", key: "companyName" },
              { label: "Current Status", key: "currentStatus" },
            ].map((item) => {
              return (
                <div className={clsx({ "d-flex": item.key !== "remarks" })}>
                  <CCardText
                    className={clsx("text-primary-label font-weight-bold m-1", {
                      "text-danger": item.key === "remarks",
                    })}
                  >
                    {item.label}:
                  </CCardText>

                  <CCardText
                    className={clsx("m-1 ms-1 text-primary-value", {
                      "text-danger": item.key === "remarks",
                    })}
                    style={{textTransform:entry[item.key] === 'rto' ? 'uppercase' : 'capitalize'}}
                  >
                    {entry[item.key]} {item.key === "paymentMode" && (entry["paymentOption"] != 'cod' && entry["paymentOption"] != null)  ? ("("+entry["paymentOption"]+")"):""}
                    {item.key === 'buyerName' && section === "placed" ? (<>
                      (<span  id={"showSwitchPayBalance"+entry.orderID} style={{color:"blueviolet", cursor:"pointer"}} onClick={() => showSwitchPayBalance(entry.userID, entry?.orderID)}>Check SwitchPe Balance</span>)
                    </>) : (<></>)}

                  </CCardText>
                </div>
              );
            })}

            {section === "placed" ? (
              <>
                {[
                  { label: "Order On", key: "placedOn" },
                ].map((item) => {
                  return (
                    <div className={clsx({ "d-flex": item.key !== "remarks" })}>
                      <CCardText
                        className={clsx("text-primary-label font-weight-bold m-1", {
                          "text-danger": item.key === "remarks",
                        })}
                      >
                        {item.label}:
                      </CCardText>

                      <CCardText
                        className={clsx("m-1 ms-1 text-primary-value", {
                          "text-danger": item.key === "remarks",
                        })}
                        style={{textTransform:"capitalize"}}
                      >
                        {entry[item.key]}
                      </CCardText>
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
            {section === "confirmed" ? (
              <>
                {[
                  { label: "Order On", key: "placedOn" },
                  { label: "Confirmed On", key: "confirmedOn" },
                ].map((item) => {
                  return (
                    <div className={clsx({ "d-flex": item.key !== "remarks" })}>
                      <CCardText
                        className={clsx("text-primary-label font-weight-bold m-1", {
                          "text-danger": item.key === "remarks",
                        })}
                      >
                        {item.label}:
                      </CCardText>

                      <CCardText
                        className={clsx("m-1 ms-1 text-primary-value", {
                          "text-danger": item.key === "remarks",
                        })}
                        style={{textTransform:"capitalize"}}
                      >
                        {entry[item.key]}
                      </CCardText>
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
            {section === "packed" ? (
              <>
                {[
                  { label: "Order On", key: "placedOn" },
                  { label: "Confirmed On", key: "confirmedOn" },
                  { label: "Ready On", key: "packagedOn" },
                ].map((item) => {
                  return (
                    <div className={clsx({ "d-flex": item.key !== "remarks" })}>
                      <CCardText
                        className={clsx("text-primary-label font-weight-bold m-1", {
                          "text-danger": item.key === "remarks",
                        })}
                      >
                        {item.label}:
                      </CCardText>

                      <CCardText
                        className={clsx("m-1 ms-1 text-primary-value", {
                          "text-danger": item.key === "remarks",
                        })}
                        style={{textTransform:"capitalize"}}
                      >
                        {entry[item.key]}
                      </CCardText>
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
            {section === "dispatched" ? (
              <>
                {[
                  { label: "Order On", key: "placedOn" },
                  { label: "Confirmed On", key: "confirmedOn" },
                  { label: "Ready On", key: "packagedOn" },
                  { label: "Dispatched ", key: "dispatchedOn" },
                  { label: "Buyer Invoice no.", key: "b2bInvoiceNumber" },
                  { label: "Seller Invoice no.", key: "invoiceNumber" },
                  { label: "Custom Invoice no.", key: "customInvoiceNumber" },

                ].map((item) => {
                  return (
                    <div className={clsx({ "d-flex": item.key !== "remarks" })}>
                      <CCardText
                        className={clsx("text-primary-label font-weight-bold m-1", {
                          "text-danger": item.key === "remarks",
                        })}
                      >
                        {item.key != 'customInvoiceNumber' ? (<>
                          {item.label}:
                        </>) : (<>
                        {entry?.customInvoiceNumber !== null ? (<>{item.label}:</>) : (<></>)}
                        </>)}

                      </CCardText>

                      <CCardText
                        className={clsx("m-1 text-primary-value", {
                          "text-danger": item.key === "remarks",
                        })}
                        style={{textTransform:"capitalize"}}
                      >
                        {item.key !== 'customInvoiceNumber' ? (<>{entry[item.key]}</>) : (<>
                          {(entry?.customInvoiceNumber !== null) ? (
                        <>
                        {entry?.customInvoiceNumber}
                        &nbsp;(DD:
                        {format(new Date(entry?.customInvoiceData?.invoiceDate*1000) ,'dd-MM-yyyy')}
                        )

                        </>) : (<></>)}
                        </>)}

                      </CCardText>
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
            {section === "delivered" ? (
              <>
                {[
                  { label: "Order On", key: "placedOn" },
                  { label: "Confirmed On", key: "confirmedOn" },
                  { label: "Ready On", key: "packagedOn" },
                  { label: "Dispatched ", key: "dispatchedOn" },
                  { label: "Delivered to Hub On", key: "deliveredToHubOn" },
                  { label: "Delivered On", key: "deliveredOn" },
                  { label: "Seller Invoice no.", key: "invoiceNumber" },
                  { label: "Buyer Invoice no.", key: "b2bInvoiceNumber" },
                ].map((item) => {
                  return (
                    <div>
                      {entry[item.key] ? (
                        <div
                          className={clsx({ "d-flex": item.key !== "remarks" })}
                        >
                          <CCardText
                            className={clsx(
                              "text-primary-label  font-weight-bold m-1",
                              {
                                "text-danger": item.key === "remarks",
                              }
                            )}
                          >
                            {item.label}:
                          </CCardText>

                          <CCardText
                            className={clsx("m-1 ms-1 text-primary-value", {
                              "text-danger": item.key === "remarks",
                            })}
                            style={{textTransform:"capitalize"}}
                          >
                            {entry[item.key] ? entry[item.key] : ""}
                            {item.key === 'invoiceNumber' && (entry?.isCustomInvoice === 1) ? (<>&nbsp;</>) : (<></>)}
                          </CCardText>
                        </div>
                      ) : (
                        ""
                      )}

                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
            {section === "rto" ? (
              <React.Fragment>
                {[
                  { label: "Order On", key: "placedOn" },
                  { label: "RTO On", key: "cancelledOn" },
                  { label: "Confirmed On", key: "confirmedOn" },
                  { label: "Ready On", key: "packagedOn" },
                  { label: "Dispatched ", key: "dispatchedOn" },
                  { label: "Delivered to Hub On", key: "deliveredToHubOn" },
                  { label: "Delivered On", key: "deliveredOn" },
                ].map((item) => {
                  return (
                    <div>
                      {entry[item.key] ? (
                        <div
                          className={clsx({ "d-flex": item.key !== "remarks" })}
                        >
                          <CCardText
                            className={clsx(
                              "text-primary-label font-weight-bold m-1",
                              {
                                "text-danger": item.key === "remarks",
                              }
                            )}
                          >
                            {item.label}:
                          </CCardText>

                          <CCardText
                            className={clsx("m-1 ms-1 text-primary-value", {
                              "text-danger": item.key === "remarks",
                            })}
                            style={{textTransform:"capitalize"}}
                          >
                            {entry[item.key] ? entry[item.key] : ""}
                          </CCardText>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              ""
            )}
            {section === "lost" ? (
              <React.Fragment>
                {[
                  { label: "Order On", key: "placedOn" },
                  { label: "Confirmed On", key: "confirmedOn" },
                  { label: "Ready On", key: "packagedOn" },
                  { label: "Dispatched ", key: "dispatchedOn" },
                  { label: "Delivered to Hub On", key: "deliveredToHubOn" },
                  { label: "Delivered On", key: "deliveredOn" },
                ].map((item) => {
                  return (
                    <div>
                      {entry[item.key] ? (
                        <div
                          className={clsx({ "d-flex": item.key !== "remarks" })}
                        >
                          <CCardText
                            className={clsx(
                              "text-primary-label font-weight-bold m-1",
                              {
                                "text-danger": item.key === "remarks",
                              }
                            )}
                          >
                            {item.label}:
                          </CCardText>

                          <CCardText
                            className={clsx("m-1 ms-1 text-primary-value", {
                              "text-danger": item.key === "remarks",
                            })}
                            style={{textTransform:"capitalize"}}
                          >
                            {entry[item.key] ? entry[item.key] : ""}
                          </CCardText>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              ""
            )}
            {/* for failed section = 1 */}
            {section === "failed" ? (
              <React.Fragment>

                {[
                  { label: "Order On", key: "placedOn" },
                ].map((item) => {
                  return (
                    <div>
                      {entry[item.key] ? (
                        <div
                          className={clsx({ "d-flex": item.key !== "remarks" })}
                        >
                          <CCardText
                            className={clsx(
                              "text-primary-label font-weight-bold m-1",
                              {
                                "text-danger": item.key === "remarks",
                              }
                            )}
                          >
                            {item.label}:
                          </CCardText>

                          <CCardText
                            className={clsx("m-1 ms-1 text-primary-value", {
                              "text-danger": item.key === "remarks",
                            })}
                            style={{textTransform:"capitalize"}}
                          >
                            {entry[item.key] ? entry[item.key] : ""}

                          </CCardText>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              ""
            )}

            {section === "cancelled" ? (
              <>
                {[
                  { label: "Order On", key: "placedOn" },
                  { label: "Confirmed On", key: "confirmedOn" },
                  { label: "Ready On", key: "packagedOn" },
                  { label: "Dispatched ", key: "dispatchedOn" },
                  { label: "Delivered to Hub On", key: "deliveredToHubOn" },
                  { label: "Delivered On", key: "deliveredOn" },
                  { label: "Cancelled On", key: "cancelledOn" },
                  { label: "Cancelled By", key: "cancelledBy" },
                  { label: "Cancellation Reason", key: "cancellationReason"}
                ].map((item) => {
                  return (
                    <div>
                      {entry[item.key] ? (
                        <div
                          className={clsx({ "d-flex": item.key !== "remarks" })}
                        >
                          <CCardText
                            className={clsx(
                              "text-primary-label font-weight-bold m-1",
                              {
                                "text-danger": item.key === "remarks",
                              }
                            )}
                          >
                            {item.label}:
                          </CCardText>

                          <CCardText
                            className={clsx("m-1 ms-1 text-primary-value", {
                              "text-danger": item.key === "remarks",
                            })}
                            style={{textTransform:"capitalize"}}
                          >
                            {entry[item.key] ? (<>
                              {item.key === 'cancellationReason' ? (<>



                                  {entry[item.key].length > 60 ? (
                                    <>
                                    <span id={"cancellationReason"+entry.orderID}>
                                    {entry[item.key].substring(0,60)}
                                    </span>
                                    <span id={"moreReason"+entry.orderID} style={{color:"blueviolet", cursor:"pointer", marginLeft:"5px"}} onClick={() => readMore(entry[item.key], entry.orderID)}>Read More</span>
                                    <span id={"lessReason"+entry.orderID} style={{display:"none", color:"blueviolet", cursor:"pointer", marginLeft:"5px"}} onClick={() => lessMore(entry[item.key].substring(0,60), entry.orderID)}>Read Less</span>
                                    </>
                                  ) : (<>{entry[item.key]}</>)}

                              </>) : (<>{entry[item.key]}</>)}

                            </>) : ("")}
                          </CCardText>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </div>
          <div className="w-40" style={{width:"50%"}}>
          {section === "delivered" || section === "rto" ? (<>
            {(pocRestricted?.indexOf(entry?.paymentMode) < 0) && (new Date(entry?.deliveredOn) > new Date('2023-08-01')) ? (<>
              <div className="mb-3" style={{backgroundColor:"#fff"}}>
                <div>
                  <span><strong>Total Due: </strong>₹{totalDue}</span> || &nbsp;
                  <span><strong>Total Collected: </strong>₹{totalCollected}</span> ||  &nbsp;
                  <span><strong>Net Balance: </strong>
                  {totalNetBalance - totalCollected === 0 ? (<>₹<span style={{color:"green"}}>0</span></>) : (<>
                    {totalNetBalance > 0 ?  <>-₹<span style={{color:"#ff0000"}}>{totalNetBalance}</span></> : <>₹<span style={{color:"green"}}>{Math.abs(totalNetBalance)}</span></>}
                  </>)}
                    </span>
                </div>
              </div>
            </>) : (<></>)}

          </>):(<></>)}
          {(section === "dispatched" || section === "delivered") ? (<>

            <div id={"biltiform"+entry.orderID}>
              {entry.lorryReceiptDetails.ID ? (<>
              <div style={{backgroundColor:"#f0f0f0", padding:"10px"}}>
                {entry.lorryReceiptDetails.receiptNumber ? (<>
                  <CCardText className={"font-weight-bold m-1"}>
                    <span className="text-primary">Lorry Receipt/Bilti Number: </span>
                    <span>{entry.lorryReceiptDetails.receiptNumber}</span>
                  </CCardText>
                </>):(<></>)}
                {entry?.shipmentDetails?.courierPartner ? (<>
                  <CCardText className={"font-weight-bold m-1"}>
                    <span className="text-primary">Delivary Partner: </span>
                    <span>{entry?.shipmentDetails?.courierPartner}</span>
                  </CCardText>
                </>):(<></>)}

                {entry.lorryReceiptDetails.generationDate ? (<>
                  <CCardText className={"font-weight-bold m-1"}>
                  <span className="text-primary">Generation Date: </span>
                  <span>{moment.utc(entry.lorryReceiptDetails.generationDate).format('DD-MM-YYYY')}</span>
                  </CCardText>
                </>) : (<></>)}

                {entry?.shipmentDetails?.currentStatus ? (<>
                  <CCardText className={"font-weight-bold m-1"}>
                  <span className="text-primary">Status: </span>
                  <span>{entry?.shipmentDetails?.currentStatus}</span>
                  </CCardText>
                </>) : (<></>)}

                  {entry.lorryReceiptDetails.receiptFile ? (<>
                    <CCardText className={"text-primary font-weight-bold m-1"}>
                      <a href={entry.lorryReceiptDetails.receiptFile} className="text-primary" target="_blank">View Lorry Reciept/Bilti File</a>
                    </CCardText>
                  </>) : (<></>)}

                  {entry?.shipmentDetails?.trackingLink ? (<>
                    <CCardText className={"text-primary font-weight-bold m-1"}>
                      <a href={entry?.shipmentDetails?.trackingLink} className="text-primary" target="_blank">Track your order</a>
                    </CCardText>
                  </>) : (<></>)}

              </div>
              {canEdit ? (<>
              {entry?.lorryReceiptDetails?.source !== 'lms' && (
                <>
                {section === 'dispatched' && (
                  <CCardText className={"text-primary  font-weight-bold m-1"}>
                    <div onClick={handleShow} style={{cursor:"pointer", textAlign:"right", fontWeight:"bolder"}}>Edit Lorry Receipt</div>
                  </CCardText>
                )}

                </>
              )}


              </>) : (<></>)}


              </>) : (<>
              {canEdit ? (<>
                <CCardText className={"text-primary  font-weight-bold m-1"}>
                  <span onClick={handleShow} style={{cursor:"pointer"}}>Add Lorry Receipt</span>
                </CCardText>
              </>) : (<></>)}
              </>)}
              {showPod ? (<div><OrderPod pod={showPod} order={entry} handleShowPod={handleShowPod} defaultAction={defaultAction} updateOrderStatusBtn={handlePocPodPayload}  /></div>) : (<></>)}

              <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                  <Modal.Title>{entry.lorryReceiptDetails.ID ? (<>{entry?.lorryReceiptDetails?.source !== 'lms' ? (<>Edit Lorry Receipt</>):("")}</>) : (<>Add Lorry Receipt</>)} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-lorry">
                <div className="form-group">
                    <label>Lorry Receipt/Bilti Number <sup>*</sup></label>
                    <Input type="text" id={"biltiNumber"+entry.orderID} placeholder="Lorry Receipt/Bilti Number" className="form-control" defaultValue={entry.lorryReceiptDetails.receiptNumber ? entry.lorryReceiptDetails.receiptNumber : ''} />
                  </div>
                  <div className="form-group">
                    <lable>Lorry Receipt/Bilti image/pdf</lable>
                    <Input type="file" id={"biltiImage"+entry.orderID} name="files" style={{height:"auto"}} placeholder="Lorry Receipt/Bilti image/pdf" onChange={(e)=>setBiltiImageBin(e.target.files)} />
                    {entry.lorryReceiptDetails.receiptFile ? (<>
                      <span style={{color:"#2185d0"}}><a href={entry.lorryReceiptDetails.receiptFile} target="_blank">View Lorry Reciept/Bilti File</a></span>
                    </>) : (<></>)}
                  </div>
                  <div className="form-group">
                    <label>Generation Date * </label>
                    <DatePicker
                        selected={biltiDate}
                        onChange={(date) => setBiltiDate(date)}
                        className={"form-control date"}
                        placeholderText="DD-MM-YYYY"
                        dateFormat="dd-MM-yyyy"
                        minDate={new Date(entry?.placedOn)}
                    />
                    <span style={{color:"#ff0000"}} id={"biltiDateError"+entry.orderID}></span>
                  </div>
                  <span style={{color:"#ff0000"}} id={"biltiError"+entry.orderID}></span>
                  <div>

                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-default me-1" onClick={handleClose}> Close </button>
                  <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); saveBilti(entry.orderID, entry.lorryReceiptDetails.receiptFile)}}>
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
                </div>

          </>):(<>

          </>)}
          <>
          <Modal show={viewBilti} onHide={handleViewBiltiClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                  <Modal.Title>{entry.lorryReceiptDetails.ID ? (<>View Lorry Receipt</>) : (<>Add Lorry Receipt</>)} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {entry.lorryReceiptDetails.receiptNumber ? (<>
                  <CCardText className={"font-weight-bold m-1"}>
                    <span className="text-primary">Lorry Receipt/Bilti Number: </span>
                    <span>{entry.lorryReceiptDetails.receiptNumber}</span>
                  </CCardText>
                </>):(<></>)}

                {entry.lorryReceiptDetails.generationDate ? (<>
                  <CCardText className={"font-weight-bold m-1"}>
                  <span className="text-primary">Generation Date: </span>
                  <span>{moment.utc(entry.lorryReceiptDetails.generationDate).format('DD-MM-YYYY')}</span>
                  </CCardText>
                </>) : (<></>)}

                  {entry.lorryReceiptDetails.receiptFile ? (<>
                    <CCardText className={"text-primary font-weight-bold m-1"}>
                      <a href={entry.lorryReceiptDetails.receiptFile} className="text-primary" target="_blank">View Lorry Reciept/Bilti File</a>
                    </CCardText>
                  </>) : (<></>)}
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-default" onClick={handleViewBiltiClose}>
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
          </>

          </div>

          <div style={{width:"25%", textAlign:"right"}}>

            <Button basic color="brown">
              ₹ {entry?.netAmount} | {entry?.quantities} Items{" "}
              {(section === "packed" || section === "dispatched") && entry?.buyerGstNumber &&  <>| &nbsp; <img src={EInvoiceTop} title="E-Invoice Required" style={{width:"20px"}} /></>}

            </Button>

            {/* <span className="alert alert-warning"> <i className="fa fa-info-circle"></i></span> */}

            <br />
            <br />
            {section === "dispatched" && <></>}

            {/* {section === "rto"  ? (
              <></>
              // <Button
              //   disabled={RpathButtonPermission()}
              //   color="green"
              //   onClick={() => updateOrderStatusBtn(confirmBtnLabel)}
              // >
              //   Delivered to Origin
              // </Button>
            ) : (
              ""
            )} */}

{showCancel ? (<><CancelOrder cancelShow={showCancel} order={entry} handleShowCancel={handleShowCancel} defaultAction={defaultAction} updateOrderStatusBtn={handleCancelPayload}  /></>) : (<></>)}


            {/* Placed Button */}
            {section === "placed"  ?
              (
                <>
                {Placed?.isEdit === 1 ? (<>
                  <button className="btn btn-default me-1" onClick={() => handleShowCancel(true)}> {" "}Cancel{" "}</button>
                </>) : (<></>)}
                {Placed.isEdit === 1 && (<>
                  <button className="btn btn-success" onClick={() => updateOrderStatusBtn(defaultAction)}>
                    {confirmBtnLabel}
                  </button>
                </>)}

                </>
              ) : (
                ""
            )}
            {/* Confirmed Button */}
            {section === "confirmed"  ?
              (
                <>
                {Confirmed?.isEdit === 1 ? (<>
                  <button className="btn btn-default me-1" onClick={() => handleShowCancel(true)}> {" "}Cancel{" "}</button>
                </>) : (<></>)}
                {Confirmed.isEdit === 1 && (<>

                  <button className="btn btn-success" onClick={() => updateOrderStatusBtn(defaultAction)}>
                    {confirmBtnLabel}
                  </button>
                </>)}

                </>
              ) : (
                ""
            )}
            {/* Ready */}
            {section === "packed" ?
            (
              <>
              {ready?.isCancelAllowed === 1 ? (<>
                <button className="btn btn-default me-1" disabled={!cancelBtnPermission()} onClick={() => handleShowCancel(true)}> {" "}Cancel{" "}</button>
                </>) : (<></>)}
              {ready.isEdit === 1 && (<>

                <button disabled={confirmBtnPermissions()} className="btn btn-success" onClick={() => (entry?.isCustomInvoice === 1) ? setSellerInvoiceModal(true) : updateOrderStatusBtn(defaultAction, entry?.buyerGstNumber)}>
                  {confirmBtnLabel}
                </button>
              </>)}
              <br />
              <button className="mt-2 btn btn-success" onClick={(e) => {e.preventDefault(); downloadInvoiceData(4, entry.orderID)}}>
                <FontAwesomeIcon icon={faDownload} /> &nbsp;{" "}Seller Estimate
              </button>
              </>
            ) : (
              ""
            )}

            {section === "dispatched" ? (<>
              <div style={{alignItems:"right"}}>
              {dispatch?.isCancelAllowed === 1 ? (<>
                <button className="btn btn-default me-1" onClick={() => handleShowCancel(true)}> {" "}Cancel{" "}</button>
              </>) : (<></>)}

              {(dispatch.isEdit === 1 || dispatch.isRtoAllowed) && (<>


              <CDropdown className="me-2">
          <CDropdownToggle
            disabled={dispatch.isEdit === 0 ? true : false}
            className="dropdown-toggle btn-info"
            // style={{backgroundColor:"green", color:"white"}}
          >
            Actions
          </CDropdownToggle>
          <CDropdownMenu>
            {dispatch.isEdit ? (<>
              {/* {!isShipped ? (<>
              <CDropdownItem key={"ship-order"}  onClick={() => handleShowOrderShip(true)}>
                Ship Order
              </CDropdownItem>
            </>) : (<>
              <CDropdownItem key={"cancel-ship-order"}  onClick={() => handleCancelOrderShip(entry?.orderID)}>
                Cancel Shipment
              </CDropdownItem>
            </>)}
            {entry?.shipmentDetails?.status === 1 &&
            <CDropdownItem key={"ship-order"}  onClick={() => handleGetShipDetail(entry?.orderID)}>
              Shipment Detail
            </CDropdownItem>
            } */}

            <CDropdownItem key={"mark-dispatch"} onClick={() => handleShowPod(true)}>
                Mark as Deliver
              </CDropdownItem>
            </>) : (<></>)}


              {dispatch.isRtoAllowed === true ? (<>
                <CDropdownItem
              key="mark-rto"

                onClick={() =>
                  // handleStatusChange('rto')
                  updateOrderStatusBtn('rto')
                }
              >
                Mark as RTO
              </CDropdownItem>
              </>) : (<></>)}

          </CDropdownMenu>
        </CDropdown>

        </>)}

              </div>

            </>) : (<></>)}

            {/* RTO */}
            {section === "rto" ?
            (
              <>
              {Rto.isEdit === 1 && (<>
                {/* <Button disabled={confirmBtnPermissions()} onClick={() => updateOrderStatusBtn(defaultAction)}>
                  {confirmBtnLabel}
                </Button> */}
              </>)}
              </>
            ) : (
              ""
            )}

            {/* Lost */}
            {section === "lost" ?
            (
              <>
              {lost.isEdit === 1 && (<>
                <button className="btn btn-success" onClick={() => (entry?.isCustomInvoice === 1) ? setSellerInvoiceModal(true) : updateOrderStatusBtn(defaultAction)}>
                  {confirmBtnLabel}
                </button>
              </>)}

              </>
            ) : (
              ""
            )}

            {section === "dispatched" ||
            section === "delivered" ||
            section === "lost" ? (
              <React.Fragment>
                <CDropdown className="me-2" style={{ paddingTop: "15px" }}>
                  <CDropdownToggle color="secondary" disabled={downloadCSVPermissions()}>
                    Download Invoices &nbsp;{" "}
                    <FontAwesomeIcon
                        icon={faDownload}
                    />
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={() => downloadInvoiceData(0, entry.orderID)}>
                      <FontAwesomeIcon icon={faDownload} /> &nbsp;{" "}Buyer Invoice (B2B to Buyer)
                    </CDropdownItem>
                    <CDropdownItem onClick={(e) => {e.preventDefault(); downloadInvoiceData(1, entry.orderID)}}>
                      <FontAwesomeIcon icon={faDownload} /> &nbsp;{" "}B2B Invoice (Seller to B2B)
                    </CDropdownItem>

                    {(entry?.isCustomInvoice === 1)? (<>
                      <CDropdownItem style={{display:"table"}}>
                        <div>
                        <span style={{cursor:"pointer"}} onClick={() => downloadInvoiceData(2, entry.orderID)}>
                          <FontAwesomeIcon
                              icon={faDownload}
                          />&nbsp;{" "}Seller Custom Invoice
                        </span>
                      {/* Allow edit custom invoice only before 24 hours */}
                      {entry?.customInvoiceEditValidUpto > 0 && compareCurrentTimeWithGiven(entry?.customInvoiceEditValidUpto) ? (
                        <span style={{float:"right"}}>
                          <span style={{color:"blue", float:"right", cursor:"pointer"}} onClick={()=>setSellerInvoiceModal(!sellerInvoiceModal)}> Edit</span>&nbsp;{" "}
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{float:"right"}}
                                color="blue"
                                onClick={()=>setSellerInvoiceModal(!sellerInvoiceModal)}
                            />
                        </span>
                      ) : (<></>)}

                        </div>
                    </CDropdownItem>
                    </>) : (<></>)}
                  </CDropdownMenu>
                </CDropdown>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={clsx("d-flex align-items-center", {
            "justify-content-between": !entry.allProfileCompleted,
            "justify-content-end": entry.allProfileCompleted,
          })}
        >
          <div className={clsx("d-flex align-items-center")}>
            {ORDER_STATUS.some(
              (seller) =>
                seller.type === "status" && seller.sections.includes(section)
            ) && (
              <CDropdown className="me-2">
                <CDropdownToggle color="info">
                  {state.sellerStatus?.label}
                </CDropdownToggle>
                <CDropdownMenu>
                  {ORDER_STATUS.filter(
                    (status) =>
                      status.type === "status" &&
                      status.key !== state.sellerStatus?.key &&
                      status.sections.includes(section)
                  ).map((status) => (
                    <CDropdownItem
                      // href="#"
                      disabled={!entry.allProfileCompleted}
                      onClick={() =>
                        handleStatusChange(
                          status,
                          "sellerStatus",
                          entry.userId,
                          `Update Status`,
                          entry.fullName || entry.email || entry.mobile
                        )
                      }
                    >
                      {status.label}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
            )}
            {ORDER_STATUS.some(
              (seller) =>
                seller.type === "approve" && seller.sections.includes(section)
            ) && (
              <CDropdown>
                <CDropdownToggle color="primary">
                  {state.approveStatus?.label}
                </CDropdownToggle>
                <CDropdownMenu>
                  {ORDER_STATUS.filter(
                    (status) =>
                      status.type === "approve" &&
                      status.key !== state.approveStatus?.key &&
                      status.sections.includes(section)
                  ).map((status) => (
                    <CDropdownItem
                      // href="#"
                      disabled={!entry.allProfileCompleted}
                      onClick={() =>
                        handleStatusChange(
                          status,
                          "approveStatus",
                          entry.userId,
                          `Update Approval Status`,
                          entry.fullName || entry.email || entry.mobile
                        )
                      }
                    >
                      {status.label}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
            )}
          </div>
        </div>
        <>
          <CNav variant="tabs">
            {TABS.filter((tab) => {
              if(tab.key === "returns" && section != "delivered"){
                return false;
              }
              if (
                  (section === "placed" || section === "confirmed" || section === "packed" || section==="dispatched" || section === "failed") &&
                  (tab.label === "Logistic" || tab.key === "pocDetail" || tab.key === "podDetail")
              ) {
                return false;
              }else if(section != "cancelled" && tab.key==="refundDetail"){
                return false
              }else if(section === "failed" && tab.label === "Buyer History") {
                return false;
              }else if(section != 'delivered' && tab.label === "Settings"){
                return false;
              }
              else{
                if((tab.key === "pocDetail" || tab.key === "podDetail") && (new Date(entry?.deliveredOn) < new Date('2023-08-01'))){
                  return false
                }else if(section === "delivered" && tab.key==="returns" && (entry.returnId === null)){
                  return false;
                }else{
                  return true
                }
              }
            }).map((tab, index) => {
              return (
                <CNavItem key={index}>
                  <CNavLink active={state.activeTabIndex === index}
                    onClick={() =>
                      handleTabChange(index, entry.userID, tab.key)
                    }
                  >
                    <div>
                      {/* Tabs other than poc and pod */}
                      {tab?.key !== 'pocDetail' && tab?.key !== 'podDetail' ? (<>

                        {tab.label}
                        {section === "dispatched" || section === "delivered" || section === "lost" || section === "failed" || section === "cancelled"  || section === "rto" ? (<>
                          {tab?.key === 'orderItem' && entry?.isCustomInvoice === 1 ? (<>
                            {entry?.sellerGrandTotal !== entry?.customInvoiceData?.invoiceAmount ? (<>
                              <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                style={{color:"red", marginLeft:"4px", fontSize:"12px"}}
                            />
                            </>) : (<>
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{color:"green", marginLeft:"4px", fontSize:"12px"}}
                            />
                            </>)}

                          </>) : (<></>)}
                        </>) : (<></>)}
                        {tab?.key === 'remarks' && (remarksCount > 0) ? (<>
                            {" ("+remarksCount+")"}
                          </>) : (<></>)}
                      </>) : (<></>)}

                      {/* Poc Tab */}
                      {tab?.key === 'pocDetail' && (new Date(entry?.deliveredOn) > new Date('2023-08-01')) && <>
                        {tab.label}
                        {tab?.key === 'pocDetail' && (!entry?.pocDetails?.cash  && !entry?.pocDetails?.cheque && !entry?.pocDetails?.online) && entry?.paymentMode === 'cod' ? (<>
                          <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              style={{marginLeft:"4px", fontSize:"12px"}}
                          />
                        </>) : (<></>)}
                      </>}

                      {/* Pod Tab */}
                      {tab?.key === 'podDetail' && (new Date(entry?.deliveredOn) > new Date('2023-08-01')) && <>
                      {tab.label}

                      {/* pod icon */}
                      {tab?.key === 'podDetail' && entry?.podDetails?.length === 0 ? (<>
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            style={{marginLeft:"4px", fontSize:"12px"}}
                        />
                      </>) : (<></>)}


                      </>}

                    </div>
                  </CNavLink>
                </CNavItem>
              );
            })}
          </CNav>

          <CTabContent>
            {TABS.filter((tab) => {
              if(tab.key === "returns" && section != "delivered"){
                return false;
              }
              if (
                (section === "placed" || section === "confirmed" || section === "packed" || section==="dispatched" || section === "failed") &&
                (tab.label === "Logistic" || tab.key === "pocDetail" || tab.key === "podDetail")
              ) {
                return false;
              }else if(section != "cancelled" && tab.key==="refundDetail"){
                return false
              }else if(section === "failed" && tab.label === "Buyer History") {
                return false;
              }else if(section != 'delivered' && tab.label === "Settings"){
                return false;
              } else {
                if((tab.key === "pocDetail" || tab.key === "podDetail") && (new Date(entry?.deliveredOn) < new Date('2023-08-01'))){
                  return false
                }else if(section === "delivered" && tab.key==="returns" && entry.returnId === null && entry?.returnStatus !== 'Initiated'){
                  return false;
                }else{
                  return true
                }

              }
              // section === "dispatched" ? entry[tab.statusKey] : true
            }).map((tab, index) => (
              <CTabPane
                visible={state.activeTabIndex === index}
                style={{
                  opacity: state.activeTabIndex === index ? 1 : 0,
                  display: state.activeTabIndex === index ? "block" : "none",
                }}
              >
                <CCard>
                  <CCardBody style={{ background: "#ebedef" }}>
                    {state.activeTabIndex === index && (
                      <tab.panelComponent
                        details={entry}
                        match={state.action}
                        isLoading={state.isFetching[tab.key]}
                        section={section}
                        canEdit={canEdit}
                        handleTotalDue={handleTotalDue}
                        updateRemarkCount={updateRemarkCount}
                      />
                    )}
                  </CCardBody>
                </CCard>
              </CTabPane>
            ))}
          </CTabContent>
        </>
      </CCardBody>
    </CCard>

  {showCancel ? (<><CancelOrder cancelShow={showCancel} order={entry} handleShowCancel={handleShowCancel} defaultAction={defaultAction} updateOrderStatusBtn={handleCancelPayload}  /></>) : (<></>)}

  {sellerInvoiceModal &&
    <SellerInvoice
      order={entry}
      handleShowSellerInvoiceModal={handleShowSellerInvoiceModal}
      defaultAction={defaultAction}
      handleSellerInvoicePayload={handleSellerInvoicePayload}  />
  }

  {showOrderShipModal &&
      <OrderShip
        order={entry}
        handleShowOrderShipModal={handleShowOrderShipModal}
        defaultAction={defaultAction}
        handleOrderShipPayload={handleOrderShipPayload}  />
    }
  </>);


};

export default OrderPage;
