import React, { useEffect, useState } from "react";
import { Button, Table, Input } from "semantic-ui-react";
import { postAPI } from "../../apiServices";
import { CBadge, CButton } from "@coreui/react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faEyeSlash, faInfoCircle, faPlusSquare, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';

const EditOrder = (props) => {
  const showToast = false;
  const sellerId = props?.orderDetail?.sellerID;
  const [addItemModal, setAddItemModal] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState();
  const [filterType, setFilterType] = useState();
  const [operator, setOperator] = useState();
  const [searchGlobally, setSearchGlobally] = useState(false);
  const [loader, setLoader] = useState(false);
  const [coupon, setCoupon] = useState(props?.state?.orderCouponName ?? '');
  // const [coupon, setCoupon] = useState();
  const [couponErrorStatus, setCouponErrorStatus] = useState()
  const [defaultOrderDetail, setDefaultOrderDetail] = useState();
  const [activeIndex, setActiveIndex] = useState();
  const [activeItemDetail, setActiveItemDetail] = useState()
  const [activeMargin, setActiveMargin] = useState();
  const [activeQuantity, setActiveQuantity] = useState();
  const [previewOrderDetail, setPreviewOrderDetail] = useState();
  const [previewOrderItemDetail, setPreviewOrderItemDetail] = useState([]);
  const [previewError, setPreviewError] = useState();
  const [orderItemDefaultList, setOrderItemDefaultList] = useState(props?.state?.entries);
  const [orderItemList, setOrderItemList] = useState(props?.state?.entries);
  const [searchResultItemList, setSearchResultItemList] = useState([]);
  const [searchResultMessage, setSearchResultMessage] = useState()
  const [newItemList, setNewItemList] = useState([]);
  const [newItemDefaultList, setNewItemDefaultList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editType, setEditType] = useState("sellerPrice");
  const [showSubmitBtn, setShowSubmitBtn] = useState();
  useEffect(() => {
    const fetchPreviewDetails = async () => {
      // setCoupon(props?.state?.orderCouponName);
      let res = await getPreviewDetail();
      if (res.status === 1) {
        setPreviewOrderDetail(res.payload);
        setPreviewOrderItemDetail(res.payload.itemDetails)
        setDefaultOrderDetail(res?.payload);
      } else {
        setPreviewError();
      }
    };
    fetchPreviewDetails();
  }, []);

  const getPreviewDetail = async (updatedItemData) => {
    setLoader(true);
    let callType = "afterChange";
    let itemDataArray = [];
    if(updatedItemData?.length > 0){
      updatedItemData?.map((item) => {
        let itemArray = {
          orderItemID: item.orderItemID ? item.orderItemID : null,
          skuID: item?.skuID,
          variantID: item?.variantID,
          editType: editType,
          quantity: item?.itemQty,
          platformMarginInPercent: item?.platformMarginInPercent,
          sellerPricePerItem: parseFloat(item?.sellerPreGstItmPrice),
          buyerPricePerItem: parseFloat(item?.buyerPreGstItmPrice)
        }
        itemDataArray.push(itemArray);
      })
    }else{
      callType = "firstTime";
      orderItemList?.map((item) => {
        let itemArray = {
          orderItemID: item?.orderItemID,
          skuID: item?.skuID,
          variantID: item?.productType === 1 ? item?.variantId : null,
          editType: editType,
          quantity: item?.setQty,
          platformMarginInPercent: item?.margin,
          sellerPricePerItem: parseFloat(item?.sellerPreGstItmPrice),
          buyerPricePerItem: parseFloat(item?.buyerPreGstItmPrice)
        }
        itemDataArray.push(itemArray);
      })

    }

    let data = {
      items: itemDataArray, couponCode:coupon ? coupon : null, callType: callType
    }
    const res = await postAPI(`/orders/${props?.orderDetail?.orderID}/edit/preview`, data, showToast)
    setLoader(false);
    if(res.status === 1){

    }else{
      setPreviewError(res.data.message);
      setPreviewOrderItemDetail(updatedItemData)
    }
      return res;
    // if(res.status === 1){
    //   setLoader(false);
    //   return res;
    // }
    // {
    //   setPreviewError(res.data.message);
    //   return res;
    // }

  }


  const hideSearch = () => {
    setShowSearch(false);
    setSearchResultItemList([])
    setSearchResultMessage()
  }

  const searchItems = async () => {
    //let sellerId = props?.orderDetails?.sellerID
    setLoader(true)
    setSearchResultItemList([])
    let value = [2]
    if(searchGlobally){
      value = []
    }
    const data = {
      page: 0,
      recPerPage: 50,
      searchKeyword,
      operator,
      filterType,
      searchGlobally: 0,
      filters: [{ param: "product-listing-type", value: value }, {
        param: "sellerId", value: [sellerId]}]
    };

    const { status, payload, message } = await postAPI("/products/list", data);
    if (status === 1) {
      const itemDetail = payload?.data.flatMap(product =>
        product.productType === 1
            ? product.skus.flatMap(sku =>
                sku.variants.map(variant => ({
                    productName: product.fullName,
                    skuName: sku.title,
                    skuCode: variant.skuCode,
                    skuID: sku.skuId,
                    images: sku.images,
                    moq: product.moq,
                    inStock: variant.stockQty,
                    sellerPricePerItem: product.sellerPreGstItmPrice,
                    buyerPricePerItem: product.buyerPreGstItmPrice,
                    showroomMargin: product.showroomMargin,
                    setItem: product.moq,
                    productType: product.productType,
                    setQty: variant.inStock,
                    isRowSelected: 1,
                    variants: variant,
                    variantID: variant.variantId
                }))
            )
            : product.skus.map(sku => ({
                productName: product.fullName,
                skuName: sku.title,
                skuCode: sku.skuCode,
                skuID: sku.skuId,
                images: sku.images,
                moq: sku.moq,
                inStock: sku.inStock,
                sellerPricePerItem: sku.sellerPreGstItmPrice,
                buyerPricePerItem: sku.buyerPreGstItmPrice,
                showroomMargin: product.showroomMargin,
                setItem: sku.moq,
                productType: product.productType,
                setQty: sku.inStock,
                isRowSelected: 1,
                variants: sku.variants,
                variantID: null
            }))
    );

      setSearchResultItemList([...itemDetail]);
      setSearchResultMessage()
    }else{
      setSearchResultMessage(message)
    }
    setLoader(false)
  };

  const addNewItem = (item)=>{
    let itemArray = {
      orderItemID:null,
      skuID: item?.skuID,
      variantID: item?.variantID,
      quantity: item?.inStock,
      itemQty: item?.inStock,
      platformMarginInPercent: item.showroomMargin,
      sellerPreGstItmPrice: item?.sellerPricePerItem,
      buyerPreGstItmPrice: item?.buyerPricePerItem
    }

    let allItem = previewOrderItemDetail?.length > 0 ? previewOrderItemDetail : [];
    allItem.push(itemArray)

    previewOrder(allItem)
    setSearchResultItemList([])
    setSearchResultMessage()
    setShowSearch(false)
  }

  const handleCheckboxChange = (index) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = [...prevSelectedRows];
      const rowIndex = newSelectedRows.indexOf(index);
      let isChecked = 0;
      if (rowIndex === -1) {
        newSelectedRows.push(index);
        isChecked = 1;
      } else {
        newSelectedRows.splice(rowIndex, 1);
      }
      const updatedSelectItemList = newItemList.map((item, i) =>
        i === index ? { ...item, ['isSelectedRow']: isChecked } : item
      );
      setNewItemList(updatedSelectItemList);
      return newSelectedRows;
    });
  };

  const handleOnFocus = (index) => {
    setActiveIndex(index);
    setActiveItemDetail({
      'sellerPreGstItmPrice' : previewOrderItemDetail[index]['sellerPreGstItmPrice'],
      'buyerPreGstItmPrice': previewOrderItemDetail[index]['buyerPreGstItmPrice'],
      'platformMarginInPercent' : previewOrderItemDetail[index]['platformMarginInPercent'],
      'itemQty' : previewOrderItemDetail[index]['itemQty']
    })

  }

  const handleOnBlurhange = async (index, field, value) => {
    const updatedItemList = previewOrderItemDetail?.map((item, i) =>
      i === index ? { ...item, [field]: parseInt(value), "editType": editType } : item
    );
    if(parseInt(previewOrderItemDetail[index][field]) === parseInt(activeItemDetail?.[field])){
      // await previewOrder(updatedItemList, field, value, index)
    }else{
      await previewOrder(updatedItemList, field, value, index)
    }

  }

  useEffect(() => {
    if (coupon === '') {
      setCouponErrorStatus()
      setPreviewError()
      couponApply(); // Call only when coupon is reset or changed
      // getPreviewDetail(previewOrderItemDetail)
    }
  }, [coupon]);

  const clearCoupon = async() => {
    await setCoupon("")
    setCouponErrorStatus()
    setPreviewError()
  }

  const couponApply = async () => {
    let res = await previewOrder(previewOrderItemDetail)
  }

  const handleInputChange = (index, field, value) => {
    setPreviewError()
    if(field === 'buyerPreGstItmPrice'){
      setEditType('buyerPrice')
    }else if(field === 'sellerPreGstItmPrice'){
      setEditType("sellerPrice")
    }else if(field === "platformMarginInPercent"){
      if(parseInt(value) < 0){
        setPreviewError("Margin value can not be less than 0")
      }
    }
  const updatedItemList = previewOrderItemDetail?.map((item, i) => {
    if (i === index) {
      let updatedItem = { ...item, [field]: parseInt(value) };
      updatedItem[field] = value
      return updatedItem;
    }
    return item;
  });
  setPreviewOrderItemDetail(updatedItemList);
};

  const handleDeleteItem = async(index, vIndex=0, type='NewItem') => {
    setPreviewError()
    if(previewOrderItemDetail.length > 1){
      const updatedNewItem = [...previewOrderItemDetail];
      await updatedNewItem.splice(index, 1);
      await previewOrder(updatedNewItem)
    }else{
      setPreviewError("Order should have atleast 1 item")
    }

  };

  const handleResetItem = (index, vIndex=0, type='NewItem') => {
    if(type === 'NewItem'){
      const updatedArray = newItemList.map((item, i) =>
        i === index ? newItemDefaultList[index] : item
      );
      setNewItemList(updatedArray)
    }else{
      const updatedArrayOrderList = orderItemList.map((item, i) =>
        i === index ? orderItemDefaultList[index] : item
      );
      setOrderItemList(updatedArrayOrderList)
    }
  }


  const closeEdit = (status) => {
    setAddItemModal(status)
    props.handleCancelEdit();
  }

  const previewOrder = async (updatedNewItem, key=null, value=null, index=null) => {
    setPreviewError()
    let res = await getPreviewDetail(updatedNewItem);
    let resPayload = {};

    if(res.data){
      setPreviewError(res.data.message);
      resPayload = res.data.payload
    }else{
      resPayload = res.payload
    }
    if(resPayload){
      setPreviewOrderDetail(resPayload)
      // setPreviewOrderItemDetail(res.payload.itemDetails)
      if(key){
        setPreviewOrderItemDetail((prevState) => {
          const updatedItems = prevState.map((item, i) =>
            i === index ? resPayload.itemDetails[i]  : item
          );
          return updatedItems;
        });
      }else{
        setPreviewOrderItemDetail(resPayload.itemDetails)
      }

      if(coupon){
        if(parseInt(resPayload.totalCouponDiscount) === 0){
          setCouponErrorStatus("invalid")
          setPreviewError("Invalid coupon code");
        }else{
          setCouponErrorStatus("valid")
          // setPreviewError(res.message);
        }
      }else{
        setCouponErrorStatus()
      }

    }
  }


  const saveEditOrder = async() => {
    setLoader(true);
    let itemDataArray = [];
    if(previewOrderItemDetail){
      previewOrderItemDetail?.map((item) => {
        let itemArray = {
          orderItemID: item.orderItemID ? item.orderItemID : null,
          skuID: item?.skuID,
          variantID: item?.variantID,
          editType: editType,
          quantity: item?.itemQty,
          platformMarginInPercent: item?.platformMarginInPercent,
          sellerPricePerItem: parseFloat(item?.sellerPreGstItmPrice),
          buyerPricePerItem: parseFloat(item?.buyerPreGstItmPrice)
        }
        itemDataArray.push(itemArray);
      })
    }else{
      orderItemList?.map((item) => {
        let itemArray = {
          orderItemID: item?.orderItemID,
          skuID: item?.skuID,
          variantID: item?.productType === 1 ? item?.variantId : null,
          editType: editType,
          quantity: item?.setQty,
          platformMarginInPercent: item?.margin,
          sellerPricePerItem: parseFloat(item?.sellerPreGstItmPrice),
          buyerPricePerItem: parseFloat(item?.buyerPreGstItmPrice)
        }
        itemDataArray.push(itemArray);
      })
    }
    let data = {
      items: itemDataArray, couponCode:coupon ? coupon : null
    }

    const res = await postAPI(`/orders/${props?.orderDetail?.orderID}/edit/save`, data)
    setLoader(false);
    if(res.status === 1){
      setAddItemModal(false)
      props?.handleSaveEdit()
    }else{
      setPreviewError(res.message)
    }
    // return res;
  }

  return (
    <>
    <Modal show={addItemModal} onHide={() => setAddItemModal(false)} backdrop="static" keyboard={false} className="fullScreenModal" style={{margin:0}}>
      <Modal.Header>
        <Modal.Title> Edit Order </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showSearch ? (<>
          {loader || previewError ? (<>
          <div className={`myTopRightToaster ${loader ? 'default' : (previewError ? 'error' : 'success')}`}>
            <div>{loader ? ('Loading....') : (previewError)}</div>
          </div>
        </>) : (<></>)}
        </>) : (<></>)}


        <div>
        <Table className={`celled newItem ${showSearch || loader ? 'disabled' :'sdhk'}`}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="top-row-no-padding" colspan={3}></Table.HeaderCell>
                <Table.HeaderCell className="top-row-no-padding" colspan={3} style={{textAlign:"center"}}>PER ITEM</Table.HeaderCell>
                <Table.HeaderCell className="top-row-no-padding" colspan={2}></Table.HeaderCell>
                <Table.HeaderCell className="top-row-no-padding" colspan={2} style={{textAlign:"center"}}>SUB TOTAL</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Action</Table.HeaderCell>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell>Seller Price</Table.HeaderCell>
                <Table.HeaderCell>Margin</Table.HeaderCell>
                <Table.HeaderCell>Buyer Price</Table.HeaderCell>
                <Table.HeaderCell>Item Per Set</Table.HeaderCell>
                <Table.HeaderCell>Set Quantity</Table.HeaderCell>
                <Table.HeaderCell>Seller</Table.HeaderCell>

                <Table.HeaderCell>Buyer</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {previewOrderItemDetail?.map((item, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell style={{verticalAlign:"middle", textAlign:"center"}}>
                      <FontAwesomeIcon icon={faTrash} title={"Remove Item"} onClick={() => handleDeleteItem(index, 0, 'OrderItem')} />
                      {/* <FontAwesomeIcon icon={faRefresh} title={"Reset Item"} onClick={() => handleResetItem(index, 'OrderItem')} /> */}
                    </Table.Cell>

                    <Table.Cell className="d-flex">
                      <div style={{padding:"10px"}}>
                      <img src={item?.productImage}  style={{width:"50px", height:"50px", borderRadius:"5px"}} />

                      </div>
                      <div className="ms-3">
                      {item.productName}
                      <span className="product-name-tooltip">
                        {" "}<FontAwesomeIcon icon={faInfoCircle} />
                        <div className="tooltip-content">
                          <table key={index} className="table table-bordered">
                          <tr>
                            <th style={{padding:"4px 0"}}>Color</th>
                            <th style={{padding:"4px 0 4px 4px"}}>Size</th>
                            <th style={{padding:"4px0 4px 4px"}}>Qty</th>
                          </tr>
                            {item?.productIdentifier?.map((row, index) => {
                              return (<tr key={index}>
                                <td>{row.color}</td>
                                <td>{row.size}</td>
                                <td>{row.itemQty}</td>
                              </tr>)
                            })}

                          </table>
                        </div>
                      </span>

                      <br />
                      <div className="pt-2 pb-2">
                        <CBadge className="p-2" color="warning">
                          {item.productType === 1 ? "Color Variant" : "Set Variant"}
                        </CBadge>
                      </div>
                    </div>

                    </Table.Cell>

                    <Table.Cell>{item.skuCode}</Table.Cell>

                    <Table.Cell style={{}}>
                      {/*Seller Price Per Item */}
                      <Input
                         style={{ maxWidth: "150px" }}
                         type="number"
                         min="1"
                         value={item?.sellerPreGstItmPrice}
                         onChange={(e) => handleInputChange(index, 'sellerPreGstItmPrice', e.target.value)}
                        onFocus={() => handleOnFocus(index)}
                         onBlur={(e) => handleOnBlurhange(index, 'sellerPreGstItmPrice', e.target.value)}
                       />
                       <div style={{fontSize:"11px"}}>{`${item?.sellerPreGstItmPrice} + ${item?.sellerItmTaxAmount} (${item.sellerTaxPercent * 100}%) = ${(parseFloat(item?.sellerPreGstItmPrice) + parseFloat(item?.sellerItmTaxAmount)).toFixed(2)}`}</div>
                    </Table.Cell>

                    <Table.Cell>

                      <div className="d-flex">
                        <select className="d-flex form-control" onChange={(e) => handleInputChange(index, 'marginType', e.target.value)}>
                          <option value="percent">%</option>
                          {/* <option value="inr">₹</option> */}
                        </select>

                        <Input
                          style={{ maxWidth: "100px" }}
                          type="number"
                          min={0}
                          defaultValue={activeIndex === index ? activeMargin : item.platformMarginInPercent}
                          onBlur={(e) => handleOnBlurhange(index, 'platformMarginInPercent', e.target.value)}
                          onFocus={() => handleOnFocus(index)}
                          onChange={(e) => handleInputChange(index, 'platformMarginInPercent', e.target.value)}
                        />
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                      {/* Buyer Price Per Item */}
                      <Input
                          style={{ maxWidth: "150px" }}
                          type="number"
                          min="1"
                          value={item.buyerPreGstItmPrice}
                          onBlur={(e) => handleOnBlurhange(index, 'buyerPreGstItmPrice', e.target.value)}
                          onFocus={() => handleOnFocus(index)}
                          onChange={(e) => handleInputChange(index, 'buyerPreGstItmPrice', e.target.value)}
                       />
                        <div style={{fontSize:"11px"}}>{`${item?.buyerPreGstItmPrice} + ${item?.buyerItmTaxAmount} (${item.buyerTaxPercent * 100}%) = ${(parseFloat(item?.buyerPreGstItmPrice) + parseFloat(item?.buyerItmTaxAmount)).toFixed(2)}`}</div>
                    </Table.Cell>

                    <Table.Cell>
                      <>{item.setItem}</>
                    </Table.Cell>
                    <Table.Cell>
                      <>
                        <Input
                          style={{ maxWidth: "100px" }}
                          type="number"
                          min="1"
                          value={item.itemQty}
                          onBlur={(e) => handleOnBlurhange(index, 'itemQty', e.target.value)}
                          onFocus={() => handleOnFocus(index)}
                          onChange={(e) => handleInputChange(index, 'itemQty', e.target.value)}
                        />
                        {/* {item.itemQty} */}
                      </>
                    </Table.Cell>

                    <Table.Cell>
                        <div>₹{(item.sellerPreGstItmPrice * item.itemQty * item.setItem).toFixed(2)}</div>
                      </Table.Cell>
                      <Table.Cell>
                      <div>₹{(item.buyerPreGstItmPrice * item.itemQty * item.setItem).toFixed(2)}</div>
                      </Table.Cell>
                  </Table.Row>
                );
              })}

              {/*
                order subtotal, handling change and discount row
                discount row show only when orderCouponDiscount exist and its value is greater than 0
              */}
              <Table.Row>
                <Table.Cell colspan={6}></Table.Cell>
                <Table.Cell colspan={2}>
                  <div className="mb-2">Order Subtotal</div>
                  <div className="mb-2">Tax Amount</div>

                </Table.Cell>

                <Table.Cell>
                <span style={{textAlign:"right", display:"inline-block"}}>
                  <div className="mb-2">
                  ₹{" "}
                    {previewOrderDetail?.sellerOrderSubTotal}
                  </div>

                  <div className="mb-2">
                  ₹{" "}{previewOrderDetail?.sellerTotalTax}
                  </div>

                  </span>
                </Table.Cell>
                <Table.Cell>
                  <span style={{textAlign:"right", display:"inline-block"}}>
                  <div className="mb-2">
                  ₹{" "}
                  {previewOrderDetail?.buyerOrderSubtotal}
                  </div>
                  <div className="mb-2">
                  ₹{" "}{previewOrderDetail?.buyerTotalTax}
                  </div>
                  </span>
                </Table.Cell>
              </Table.Row>

              {/* <Table.Row>
                <Table.Cell colspan={6}></Table.Cell>
                <Table.Cell colspan={2}>
                  <span>Tax Amount</span>

                </Table.Cell>
                <Table.Cell>
                  <div className="2" style={{textAlign:"right"}}>
                  ₹{" "}
                  {previewOrderDetail?.sellerTotalTax}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div style={{textAlign:"right"}}>
                  ₹{" "}
                  {previewOrderDetail?.buyerTotalTax}
                  </div>
                </Table.Cell>
              </Table.Row> */}

              <Table.Row>
              <Table.Cell colspan={6}></Table.Cell>
                <Table.Cell colspan={2}>
                  <div className="mb-2">Handling Charges</div>
                  <div className="mb-2">Coupon Discount</div>

                </Table.Cell>

                <Table.Cell>
                <span style={{textAlign:"right", display:"inline-block"}}>

                  <div className="mb-2">
                  ₹{" "}{previewOrderDetail?.sellerTotalShipping}
                  </div>

                  <div className="mb-2">
                    -
                    {/* {previewOrderDetail?.totalCouponDiscount} */}
                  </div>
                  </span>
                </Table.Cell>
                <Table.Cell>
                  <span style={{textAlign:"right", display:"inline-block"}}>
                  <div className="mb-2">
                  ₹{" "}{previewOrderDetail?.buyerTotalShipping}
                  </div>
                  <div className="mb-2">
                  -₹{" "}{previewOrderDetail?.totalCouponDiscount}
                  </div>
                  </span>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell colspan={6}></Table.Cell>
                <Table.Cell colspan={2}>
                  <div className="mb-2">
                    <div>Coupon Code</div>
                    {/* {props?.state?.orderCouponName && <div style={{fontWeight:"bold"}}>({props?.state?.orderCouponName})</div>} */}

                  </div>
                </Table.Cell>
                <Table.Cell colspan={2}>
                  <Input type="text" id="couponField" className={`couponField ${couponErrorStatus}`} style={{textTransform:"uppercase"}} placeholder="Coupon Code" value={coupon.toUpperCase() || '' } onChange={(e) => setCoupon(e.target.value.toUpperCase())} onBlur={() => couponApply()}
                    readOnly={((props?.orderDetail?.currentStatus === 'placed') || (props?.orderDetail?.currentStatus === 'confirmed') || (props?.orderDetail?.currentStatus === 'packed')) ? false : true} />

                  {coupon && (
                    <button className="clear-button" onClick={() => setCoupon('')}>
                      &times;
                    </button>
                  )}
                </Table.Cell>
              </Table.Row>

              {/* grand total row consist of seller and buyer subtotal */}
              <Table.Row>
                <Table.Cell colspan={6}></Table.Cell>
                <Table.Cell colspan={2}>
                  <div style={{fontWeight:"bold", fontSize:"14px"}}>Gross Total ({`${previewOrderDetail?.totalNoOfItems} Items`})</div>
                  <div style={{fontSize:"14px"}}>Round Off </div>

                </Table.Cell>
                <Table.Cell>
                  <div className="2" style={{fontWeight:"bold", fontSize:"14px", textAlign:"right"}}>
                    ₹{" "} {previewOrderDetail?.sellerGrandTotal}
                  </div>
                  <div className="2" style={{fontSize:"14px", textAlign:"right"}}>
                    ₹{" "} {-(previewOrderDetail?.sellerGrandTotal - previewOrderDetail?.sellerRoundOffGrandTotal).toFixed(2)}
                  </div>

                </Table.Cell>
                <Table.Cell>
                  <div style={{fontWeight:"bold", fontSize:"14px", textAlign:"right"}}>
                    ₹{" "} {previewOrderDetail?.buyerGrandTotal}
                  </div>
                  <div style={{fontSize:"14px", textAlign:"right"}}>
                    ₹{" "} {-(previewOrderDetail?.buyerGrandTotal - previewOrderDetail?.buyerRoundOffGrandTotal).toFixed(2)}
                  </div>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell colspan={6}></Table.Cell>
                <Table.Cell colspan={2}>
                  <div style={{fontSize:"14px"}}>Total Margin </div>

                </Table.Cell>
                <Table.Cell>
                  <div className="2" style={{fontSize:"14px", textAlign:"right"}}>
                  {`${previewOrderDetail?.totalMarginPercent}% `}
                  </div>
                </Table.Cell>
                <Table.Cell>

                </Table.Cell>
              </Table.Row>


                {/* Payment detail row
                  it shows advance or all payment received and the payment to be collected */}
                  {(props?.state?.advance?.actual + props?.state?.advance?.sCash) > 0 ? (
                    <Table.Row>

                      <Table.Cell colspan={6}></Table.Cell>
                      <Table.Cell colspan={2}>
                        <span style={{fontWeight:"bold", fontSize:"14px"}}>Advance </span>

                      </Table.Cell>
                      <Table.Cell>-
                      </Table.Cell>
                      <Table.Cell>
                        <div style={{fontSize:"14px", textAlign:"right"}}>
                        <div style={{fontWeight:"bold"}}>
                          ₹{" "}{props?.state?.advance?.sCash > 0 ? props?.state?.advance?.actual - props?.state?.advance?.sCash : props?.state?.advance?.actual}

                        </div>{" "} {" "}
                        {props?.state?.advance?.sCash > 0 ? (<>
                          <div style={{fontSize:"12px", textDecoration:"line-through"}}>₹{" "}{props?.state?.advance?.actual}</div>

                        </>) : (<></>)}


                        </div>
                      </Table.Cell>
                      </Table.Row>
                  ):(<></>)}


              <Table.Row>
              <Table.Cell colspan={6}></Table.Cell>
              <Table.Cell colspan={2}>
                <div style={{fontWeight:"bold", fontSize:"14px"}}>Amount to be collected</div>
                <div style={{color:"#827c7c"}}>Actual Amount</div>
                <div style={{color:"#827c7c"}}>S-Cash Redeemed</div>
              </Table.Cell>
              <Table.Cell>-
              </Table.Cell>
              <Table.Cell>
                <div style={{fontSize:"14px", textAlign:"right"}}>
                <div style={{fontWeight:"bold"}}>
                  ₹{" "} {previewOrderDetail?.balance?.final}
                  </div>
                  <div>₹{" "}{previewOrderDetail?.balance?.actual}</div>
                  <div>₹{" "}{previewOrderDetail?.balance?.sCashUsed}</div>
                </div>
              </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        {showSearch ? (
          <div key="searchResult" className="searchResult">
            {loader ? (<>
              Loading....
            </>) : (<>
              {searchResultItemList.length === 0 ? (<>
              {searchResultMessage}
            {/* No data found */}
          </>) : (<>
            <table className="table table-bordered">
            <thead>
              <tr>
                <th>Action</th>
                <th>Product</th>
                <th>SKU</th>
                <th style={{}}>
                  Seller Price
                </th>
                <th style={{}}>
                  Buyer Price
                </th>
                <th>Item Per Set</th>
                <th>In stock</th>
              </tr>
            </thead>

            <tbody>
        {searchResultItemList.map((item, index) => {
            let instock = item.productType === 2 ? item.inStock : item.variants.stockQty;
              let moq = item.moq
              if(instock > 0){
                return (
                  <tr key={`${index}`}>
                    <td>
                    <FontAwesomeIcon icon={faPlusSquare} title={"Add Item"} onClick={() => addNewItem(item)} />

                    </td>
                    <td className="d-flex">

                      <div className="ms-3">
                        {item.productName}
                        <span>
                          <>
                          {item.productType === 1 ? (
                            <>

                            </>
                          ) : (<>
                          {" "}(Size:
                          {item.variants.map((variant, index) =>
                            `${variant.size}-${variant.stockQty} ${((item.variants.length - 1) > index ? ', ': '')}`

                          )}
                          )
                          </>)}

                        </>
                          {/* {item.productType === 1 ? `(Color: ${item.variants.colorName})` : `(Size: ${item.variants.size})`}<br /> */}
                        </span>
                        <br />
                        <CBadge className="p-2" color="warning">
                          {item.productType === 1 ? "Color Variant" : "Set Variant"}
                        </CBadge>

                      </div>
                    </td>
                    <td>

                      {item.productType === 1 ? item.skuCode : item.skuCode}
                    </td>
                    <td> {item.sellerPricePerItem} </td>
                    <td>{item.buyerPricePerItem}</td>
                    <td> {item.setItem} </td>
                    <td>{instock}</td>

                  </tr>
                );
              }
            })}
            </tbody>
            </table>
          </>)}
            </>)}
        </div>
        ) : (<></>) }

      </Modal.Body>
      <Modal.Footer>
        {showSearch ? (<>
        <Table className="searchForm add-item-in-order" style={{border:"none"}}>
          <Table.Row key={"addNewRow"}>

                <Table.Cell>
                  <select className="form-control d-flex w-20" name="filterType"
                    onChange={(e) => setFilterType(e.target.value)}
                    id="filterType"
                  >
                    <option value="">Search By</option>
                    <option value="title">Title</option>
                    <option value="sku">SKU</option>
                  </select>
                </Table.Cell>
                <Table.Cell>
                  <select className="form-control d-flex w-20" id="operator" name="operator" onChange={(e) => setOperator(e.target.value)}>
                    <option value="">Select Operand</option>
                    <option value={"equals"}>Equals</option>
                    <option value={"contains"}>Contains</option>
                  </select>
                </Table.Cell>
                <Table.Cell>
                  <input
                    type="text"
                    id="searchKeywordId"
                    className="form-control d-flex"
                    placeholder="Product name, and sku"
                    onChange={(e) => setSearchKeyword(e.target.value)}
                  />
                </Table.Cell>
                {(props?.section === 'delivered' || props?.section === 'dispatched') &&
                  <Table.Cell>
                    <input
                    type="checkbox"
                    className="checkbox"
                    onChange={(e) => setSearchGlobally(!searchGlobally)}
                    style={{float:"left"}}
                  />
                  <span style={{paddingTop:"3px", paddingLeft:"5px"}}>Search Global</span>
                  </Table.Cell>
                }


                <Table.Cell>
                  <Button className="btn btn-info me-1" onClick={() => searchItems()}> <FontAwesomeIcon icon={faSearch} /> </Button>
                  <Button className="btn btn-danger" onClick={() => hideSearch()}> <FontAwesomeIcon icon={faEyeSlash} /> </Button>
                </Table.Cell>
                <Table.Cell>
                <button className="btn btn-info me-1" disabled={showSearch || previewError ? true : false} style={{float:"right"}} onClick={() => saveEditOrder()}> Save </button>
                <button className="btn btn-default me-1" style={{float:"right"}} onClick={() => closeEdit(false)}> Close </button>
                </Table.Cell>

              </Table.Row>
              </Table>
        </>) : (<>
        {showSubmitBtn ? (<>
        <p>Do you want to save order?</p>
          <button className="btn btn-info" onClick={() => saveEditOrder()}> Save </button>
          <button className="btn btn-default" onClick={() => setShowSubmitBtn(false)}> Cancel </button>
        </>) : (<>
          <button className="btn btn-info" onClick={() => setShowSearch(true)}>Add New Item</button>
          <button className="btn btn-info"  disabled={showSearch || previewError ? true : false} onClick={() => setShowSubmitBtn(true)}> Submit </button>
          <button className="btn btn-default" onClick={() => closeEdit(false)}> Close </button>
        </>)}

        </>)}


      </Modal.Footer>
    </Modal>
    </>
  )
}

export default EditOrder
